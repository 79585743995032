import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";

import { RootState } from "@store/store";
import { createRowTable, createSimpleRow } from "@helpers/components";
// import { CategoriasAnalisisGrafico } from "@components/CategoriasAnalisisGrafico";

import DownloadIcon from "@assets/download_icon.svg";
import { GraficoBarrasGenerico } from "@components/GraficoBarrasGenerico";
import { GraficoDonaGenerico } from "@components/GraficoDonaGenerico";

export const Dashboard = () => {
  const [periodSelected, setPeriodSelected] = useState<number>(1);

  const handleOnPeriodClicked = (value: number) => {
    setPeriodSelected(value);
  };

  const handleOnDownloadReportClicked = () => {};
  return (
    <Grid container mt={2}>
      <Grid container width={"100%"} direction={"row"} justifyContent={"end"} alignItems={"center"} spacing={1}>
        <Typography variant="body1" color="#002652" fontWeight={700} textAlign={"center"}>
          Periodo
        </Typography>
        <Button
          onClick={() => handleOnPeriodClicked(1)}
          sx={{
            backgroundColor: periodSelected === 1 ? "#528CD6" : "#A3D4E8",
            color: periodSelected === 1 ? "#FFF" : "#000",
            fontSize: "13px",
          }}
        >
          1 Año
        </Button>
        <Button
          onClick={() => handleOnPeriodClicked(2)}
          sx={{
            backgroundColor: periodSelected === 2 ? "#528CD6" : "#A3D4E8",
            color: periodSelected === 2 ? "#FFF" : "#000",
            fontSize: "13px",
          }}
        >
          2 Años
        </Button>
        <Button
          onClick={() => handleOnPeriodClicked(3)}
          sx={{
            backgroundColor: periodSelected === 3 ? "#528CD6" : "#A3D4E8",
            color: periodSelected === 3 ? "#FFF" : "#000",
            fontSize: "13px",
          }}
        >
          3 Años
        </Button>
      </Grid>
      <Grid p={2} width={"100%"}>
        <GraficoBarrasGenerico
          title="Ventas"
          labels={[
            `ene 2023`,
            `feb 2023`,
            `mar 2023`,
            `abr 2023`,
            `may 2023`,
            `jun 2023`,
            `jul 2023`,
            `ago 2023`,
            `sep 2023`,
            `oct 2023`,
            `nov 2023`,
            `dic 2023`,
          ]}
          colors={["rgb(0, 38, 84)"]}
          data={[[40000, 100000, 140000, 120000, 501000, 123000, 343000, 512000, 421000, 234000, 533000, 321000]]}
        />
      </Grid>
      <Grid p={2} mt={2} width={"100%"}>
        <GraficoBarrasGenerico
          title="Gastos"
          labels={[
            `ene 2023`,
            `feb 2023`,
            `mar 2023`,
            `abr 2023`,
            `may 2023`,
            `jun 2023`,
            `jul 2023`,
            `ago 2023`,
            `sep 2023`,
            `oct 2023`,
            `nov 2023`,
            `dic 2023`,
          ]}
          colors={["rgb(58, 142, 220)"]}
          data={[[40000, 100000, 140000, 120000, 501000, 123000, 343000, 512000, 421000, 234000, 533000, 321000]]}
        />
      </Grid>
      <Grid p={2} mt={2} width={"100%"}>
        <GraficoBarrasGenerico
          stacked
          title="Flujo"
          subtitle="(Ingreso, Egresos)"
          labels={[
            `ene 2023`,
            `feb 2023`,
            `mar 2023`,
            `abr 2023`,
            `may 2023`,
            `jun 2023`,
            `jul 2023`,
            `ago 2023`,
            `sep 2023`,
            `oct 2023`,
            `nov 2023`,
            `dic 2023`,
          ]}
          colors={["rgb(58, 142, 220)", "rgb(242, 112, 79)"]}
          data={[
            [40000, 100000, 140000, 120000, 501000, 123000, 343000, 512000, 421000, 234000, 533000, 321000],
            [-40000, -100000, -140000, -120000, -501000, -123000, -343000, -512000, -421000, -234000, -533000, -321000],
          ]}
        />
      </Grid>

      <Grid p={2} mt={2} width={"100%"} container>
        <Grid size={12} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}>
          <Typography variant="h3" fontWeight={700} color="#002652" mb={2}>
            Clientes y Proveedores
          </Typography>
        </Grid>
        <Grid size={6} mt={1}>
          <Box borderRight={"1px solid rgba(163, 212, 232, 0.5)"} px={2}>
            <Typography variant="h4" fontWeight={600} color={"#002652"}>
              Top Clientes
            </Typography>
            <GraficoBarrasGenerico
              title=""
              labels={[
                "Publico en General",
                "Otros",
                "Paytomic Mexico",
                "Susana Fernandez",
                "Total Pass",
                "Ricardo Uraga",
                "Servicios Promedic",
                "Drako Motors",
                "Padeleros",
              ]}
              data={[[19800000, 1200000, 1100000, 400000, 300000, 300000, 200000, 100000, 100000]]}
              colors={["rgb(0, 38, 84)"]}
              horizontalBars
            />
          </Box>
        </Grid>
        <Grid size={6} mt={1}>
          <Box borderRight={"1px solid rgba(163, 212, 232, 0.5)"} px={2}>
            <Typography variant="h4" fontWeight={600} color={"#002652"}>
              Top Proveedores
            </Typography>
            {
              <GraficoBarrasGenerico
                title=""
                colors={["rgb(0, 38, 84)"]}
                horizontalBars
                labels={[
                  "Otros",
                  "Saracuin SA de CV",
                  "Omnifruit SA de CV",
                  "Corina Aguilar",
                  "Antonio Fernadez",
                  "Maria Aide Garza",
                  "Sandra Aceves",
                  "Javier Lebron Garcia",
                ]}
                data={[[4000000, 3400000, 1400000, 1400000, 1300000, 1200000, 800000, 800000]]}
              />
            }
          </Box>
        </Grid>
      </Grid>
      <Grid p={2} mt={2} width={"100%"} container>
        <Grid size={12} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}>
          <Typography variant="h3" fontWeight={700} color="#002652" mb={2}>
            Productos y Servicios
          </Typography>
        </Grid>
        <Grid size={6} mt={1}>
          <Box borderRight={"1px solid rgba(163, 212, 232, 0.5)"}>
            <GraficoDonaGenerico
              title="Vendidos"
              data={[2, 1, 2, 2, 2, 90]}
              labels={[
                "Clubes",
                "Aspecto personal",
                "Otros",
                "Servicios de asesoría de gestión",
                "Servicios inmobiliarios",
              ]}
              colors={["rgb(16, 30, 58)"]}
            />
          </Box>
        </Grid>
        <Grid size={6} mt={1}>
          <Box>
            <GraficoDonaGenerico
              title="Comprados"
              data={[2, 1, 2, 2, 2, 90]}
              labels={[
                "Otros",
                "Software",
                "Servicios Inmobiliarios",
                "Servicios de mantenimiento",
                "Comercialización y distribución",
                "Servicios de recursos humanos",
              ]}
              colors={["rgb(16, 30, 58)"]}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container width={"100%"} direction={"column"} alignContent={"end"} mt={4}>
        <Button
          endIcon={<img src={DownloadIcon} />}
          variant="text"
          sx={{ color: "#528CD6" }}
          onClick={handleOnDownloadReportClicked}
        >
          Descargar Reporte
        </Button>
      </Grid>
    </Grid>
  );
};
