import { CustomTabs, CustomTabPanel } from "@components/CustomTabs";
import { Resumen } from "@components/SolicitudSegmentos/ResumenCliente/Resumen";

export const ResumenCliente = () => {
  return (
    <>
      <CustomTabs titleTabs={["Resumen Cliente"]}></CustomTabs>
      <CustomTabPanel index={0} value={0}>
        <Resumen />
      </CustomTabPanel>
    </>
  );
};
