import React, { useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  Button,
  Typography,
  Checkbox,
  Skeleton,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { RootState } from "@store/store";
import { DocumentInfo } from "@interfaces/documents";
import { SignatoryItem, SendToSignPayload } from "@interfaces/signatures";
import { useLazyGetSignatoriesQuery, useSendToSignMutation } from "@services/api/signatures";
import { useGetUrlToDownloadDocMutation } from "@services/api/documents";

interface EnviarMiFielProps {
  document: DocumentInfo;
  onClose: () => void;
}

const ModalSendToSign = (props: EnviarMiFielProps) => {
  const { document, onClose } = props;
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const [triggerGetSignatories, signatoriesInfo] = useLazyGetSignatoriesQuery();
  const [triggerSendToSign] = useSendToSignMutation();
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();

  const [viewer, setViewer] = React.useState("iframe");
  const [fileUrl, setFileUrl] = React.useState("");
  const [internalSigners, setInternalSigners] = React.useState<SignatoryItem[]>([]);
  const [externalSigners, setExternalSigners] = React.useState<SignatoryItem[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (_values) => {
      try {
        setIsSending(true);
        setErrorMessage("");
        if (!applicationSelected || !applicationSelected.id) return;
        if (!document || !document.id) return;

        const payload: SendToSignPayload = {
          loan_application_id: applicationSelected.id,
          document_id: document.id,
          signers: [],
        };

        internalSigners
          .filter((item) => item.checked)
          .forEach((item) => {
            payload.signers.push({
              name: item.name,
              email: item.email,
              rfc: item.rfc,
              type: "internal",
            });
          });

        externalSigners
          .filter((item) => item.checked)
          .forEach((item) => {
            payload.signers.push({
              name: item.name,
              email: item.email,
              rfc: item.rfc,
              type: "external",
            });
          });

        if (payload.signers.length === 0) {
          setErrorMessage("Debes seleccionar al menos un firmante");
          return;
        }

        await triggerSendToSign(payload).unwrap();

        onClose();
      } catch (e: any) {
        if (e.status === 409) {
          setErrorMessage("El documento ya ha sido enviado a firmar");
        } else if (e.status === 402) {
          setErrorMessage("No tienes suficientes créditos para enviar a firmar");
        } else {
          setErrorMessage("Ha ocurrido un error al enviar a firmar");
        }
      } finally {
        setIsSending(false);
      }
    },
  });

  const handleCheckInternal = (index: number) => {
    let newSigners = [...internalSigners];
    newSigners[index].checked = !newSigners[index].checked;
    setInternalSigners(newSigners);
  };

  const handleCheckExternal = (index: number) => {
    let newSigners = [...externalSigners];
    newSigners[index].checked = !newSigners[index].checked;
    setExternalSigners(newSigners);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isLoading) {
          return;
        }

        setIsLoading(true);
        const file_extension = document.file_name.split(".").pop();
        const response = await triggerPostUrlDocument(document.url).unwrap();
        setFileUrl(response);

        if (["jpg", "jpeg", "png", "gif", "bmp"].includes(file_extension || "")) {
          setViewer("image");
        } else if (["pdf"].includes(file_extension || "")) {
          setViewer("iframe");
        } else {
          setViewer("btn_download");
        }
      } catch (e) {
        console.log("Ha ocurrido un error", e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [document]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!applicationSelected || !applicationSelected.id) return;

        setIsLoading(true);
        const response = await triggerGetSignatories(applicationSelected.id).unwrap();
        const result_internals: SignatoryItem[] = [];
        const result_external: SignatoryItem[] = [];

        response.internals.forEach((item: any) => {
          result_internals.push({
            name: item.name,
            rfc: item.rfc,
            email: item.email,
            role: item.role || "",
            checked: false,
          });
        });

        response.externals.forEach((item: any) => {
          result_external.push({
            name: item.name,
            rfc: item.rfc,
            email: item.email,
            role: item.role || "",
            checked: false,
          });
        });

        setInternalSigners(result_internals);
        setExternalSigners(result_external);
      } catch (e) {
        console.log("Ha ocurrido un error", e);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [applicationSelected]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth={false}
      sx={{
        "& .MuiDialog-paper": {
          width: "90%",
          height: "90%",
          borderRadius: "10px",
          paddingTop: "10px",
          maxWidth: "1200px",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <span>Enviar a firmar: {document.file_name}</span>
          <IconButton
            onClick={onClose}
            aria-label="close"
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid2 container height={"100%"}>
          <Grid2 size={8}>
            {isLoading && (
              <Box height="100%">
                <Skeleton width={"100%"} height={"90%"} animation="wave" variant="rectangular" />
              </Box>
            )}
            {!isLoading && viewer === "image" && (
              <img
                src={fileUrl}
                alt="Imagen"
                style={{
                  width: "100%",
                  maxHeight: "100%",
                }}
              />
            )}
            {!isLoading && viewer === "iframe" && (
              <object data={fileUrl} type="application/pdf" width={"100%"} height={"100%"}>
                <div>No se puede visualizar el archivo</div>
              </object>
            )}
            {!isLoading && viewer === "btn_download" && (
              <>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                  <Typography fontSize={30}>No se puede visualizar el archivo</Typography>
                  <Button
                    variant="default"
                    fullWidth
                    sx={{ width: "30%", mt: 4 }}
                    onClick={() => {
                      window.open(fileUrl, "_blank");
                    }}
                  >
                    Descargar
                  </Button>
                </Box>
              </>
            )}
          </Grid2>
          <Grid2 size={4} padding={2}>
            <form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
              <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                <Box display="flex" flexDirection="column" gap={2} mt={2}>
                  <Box>
                    <Typography variant="h6">Firmantes internos</Typography>
                    <Box display="flex" flexDirection="column" gap={1} mt={2}>
                      {signatoriesInfo.isLoading && (
                        <>
                          <Skeleton variant="text" width={"100%"} height={40} />
                          <Skeleton variant="text" width={"100%"} height={40} />
                        </>
                      )}

                      {!signatoriesInfo.isLoading &&
                        internalSigners.map((signer, index) => (
                          <Box key={index}>
                            <Grid2 container>
                              <Grid2 size={2}>
                                <Checkbox
                                  checked={signer.checked}
                                  onChange={() => handleCheckInternal(index)}
                                  color="primary"
                                  disabled={isSending}
                                />
                              </Grid2>
                              <Grid2 size={10} display="flex" flexDirection="column">
                                <Typography variant="body1">{signer.rfc}</Typography>
                                <Typography variant="body2">{signer.name}</Typography>
                                <Typography variant="body2">{signer.role}</Typography>
                              </Grid2>
                            </Grid2>
                          </Box>
                        ))}

                      {!signatoriesInfo.isLoading && internalSigners.length === 0 && (
                        <Box>
                          <Typography variant="body1">No se han encontrado firmantes</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box>
                    <Typography variant="h6">Firmantes externos</Typography>
                    <Box display="flex" flexDirection="column" gap={1} mt={2}>
                      {signatoriesInfo.isLoading && (
                        <>
                          <Skeleton variant="text" width={"100%"} height={40} />
                          <Skeleton variant="text" width={"100%"} height={40} />
                        </>
                      )}

                      {!signatoriesInfo.isLoading &&
                        externalSigners.map((signer, index) => (
                          <Box key={index}>
                            <Grid2 container>
                              <Grid2 size={2}>
                                <Checkbox
                                  checked={signer.checked}
                                  onChange={() => handleCheckExternal(index)}
                                  color="primary"
                                  disabled={isSending}
                                />
                              </Grid2>
                              <Grid2 size={10} display="flex" flexDirection="column">
                                <Typography variant="body1">{signer.rfc}</Typography>
                                <Typography variant="body2">{signer.name}</Typography>
                              </Grid2>
                            </Grid2>
                          </Box>
                        ))}

                      {!signatoriesInfo.isLoading && externalSigners.length === 0 && (
                        <Box>
                          <Typography variant="body1">No se han encontrado firmantes</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  {errorMessage && (
                    <Box mt={4}>
                      <FormHelperText error>{errorMessage}</FormHelperText>
                    </Box>
                  )}
                </Box>

                <Box display="flex" gap={2}>
                  <Button
                    type="button"
                    variant="blue_outlined"
                    fullWidth
                    onClick={onClose}
                    disabled={isLoading || isSending}
                  >
                    Cancelar
                  </Button>
                  <Button type="submit" variant="default" fullWidth disabled={isLoading || isSending}>
                    Enviar a firmar
                  </Button>
                </Box>
              </Box>
            </form>
          </Grid2>
        </Grid2>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSendToSign;
