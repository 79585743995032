import {
  Box,
  Button,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import DownloadIcon from "@assets/download_icon.svg";

export const Financieros = () => {
  const handleOnDownloadReportClicked = () => {};
  return (
    <Grid container direction={"column"}>
      <Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Balance General
        </Typography>
        <Box maxWidth={"65%"} minWidth={"600px"}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#002652" }}>
                  <TableCell sx={{ color: "#FFF" }} align="center">
                    <Typography variant="h4">Categoría / Periodo</Typography>
                  </TableCell>
                  <TableCell sx={{ color: "#FFF" }} align="center">
                    <Typography variant="h4">2022</Typography>
                  </TableCell>
                  <TableCell sx={{ color: "#FFF" }} align="center">
                    <Typography variant="h4">2023</Typography>
                  </TableCell>
                  <TableCell sx={{ color: "#FFF" }} align="center">
                    <Typography variant="h4">2024</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ backgroundColor: "#528cd6" }}>
                  <TableCell>
                    <Typography color="#FFF">- Activo</Typography>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} color="#FFF">
                      <Typography component={"span"} width={"100%"} fontWeight={500}>
                        $
                      </Typography>
                      <Typography component={"span"} color="#FFF" fontWeight={500}>
                        15,683,997
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} color="#FFF">
                      <Typography component={"span"} width={"100%"} fontWeight={500}>
                        $
                      </Typography>
                      <Typography component={"span"} fontWeight={500}>
                        15,683,997
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} color={"#FFF"}>
                      <Typography component={"span"} width={"100%"} fontWeight={500}>
                        $
                      </Typography>
                      <Typography component={"span"} fontWeight={500}>
                        15,683,997
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#e3f2f8" }}>
                  <TableCell>
                    <Typography pl={2} fontWeight={500}>
                      + Activo a corto plazo
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#e3f2f8" }}>
                  <TableCell>
                    <Typography pl={2} fontWeight={500}>
                      + Activo a largo plazo
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"}>
                      <Typography component={"span"} width={"100%"} fontWeight={500}>
                        $
                      </Typography>
                      <Typography component={"span"} fontWeight={500}>
                        15,683,997
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"}>
                      <Typography component={"span"} width={"100%"} fontWeight={500}>
                        $
                      </Typography>
                      <Typography component={"span"} fontWeight={500}>
                        15,683,997
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"}>
                      <Typography component={"span"} width={"100%"} fontWeight={500}>
                        $
                      </Typography>
                      <Typography component={"span"} fontWeight={500}>
                        15,683,997
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: "#528cd6" }}>
                  <TableCell>
                    <Typography color="#FFF" fontWeight={500}>
                      - Pasivo
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} color="#FFF">
                      <Typography component={"span"} width={"100%"} fontWeight={500}>
                        $
                      </Typography>
                      <Typography component={"span"} fontWeight={500}>
                        15,683,997
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} color="#FFF">
                      <Typography component={"span"} width={"100%"} fontWeight={500}>
                        $
                      </Typography>
                      <Typography component={"span"} fontWeight={500}>
                        15,683,997
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} color="#FFF">
                      <Typography component={"span"} width={"100%"} fontWeight={500}>
                        $
                      </Typography>
                      <Typography component={"span"} fontWeight={500}>
                        15,683,997
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Estado de Resultados
        </Typography>
        <Box maxWidth={"65%"} minWidth={"600px"}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#002652" }}>
                  <TableCell sx={{ color: "#FFF" }} align="center">
                    <Typography variant="h4">Categoría / Periodo</Typography>
                  </TableCell>
                  <TableCell sx={{ color: "#FFF" }} align="center">
                    <Typography variant="h4">2022</Typography>
                  </TableCell>
                  <TableCell sx={{ color: "#FFF" }} align="center">
                    <Typography variant="h4">2023</Typography>
                  </TableCell>
                  <TableCell sx={{ color: "#FFF" }} align="center">
                    <Typography variant="h4">2024</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody></TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
      <Grid container width={"100%"} direction={"column"} alignContent={"end"} mt={4}>
        <Button
          endIcon={<img src={DownloadIcon} />}
          variant="text"
          sx={{ color: "#528CD6" }}
          onClick={handleOnDownloadReportClicked}
        >
          Descargar Reporte
        </Button>
      </Grid>
    </Grid>
  );
};
