import React, { ReactNode, useEffect, useState } from "react";

import { Avatar, Box, Container, Grid2, IconButton, Link, Typography } from "@mui/material";
import { GridColDef, GridPaginationModel, GridRenderCellParams, GridSortModel, useGridApiRef } from "@mui/x-data-grid";
import { esES } from "@mui/x-data-grid/locales";

import { UUID } from "crypto";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatCurrency, formatHours } from "@helpers/formats";

import { Application } from "@interfaces/application";
import { useGetStatusQuery } from "@services/api/catalogs";
import { StripedDataGrid } from "@components/CustomDataGrid";
import { CustomPopupModal } from "@components/CustomPopupModal";
import { setApplicationSelected } from "@store/slices/appSlice";
import { useLazyGetApplicationsQuery } from "@services/api/applications";

import { LoanAppComments } from "./LoanAppComments";
import { LoanAppChannelInfo } from "./LoanAppChannelInfo";
import { LoanApplicationInfo } from "./LoanApplicationInfo";
import { LoanAppLostInterest } from "./LoanAppLostInterest";
import { LoanAppChangeInterest } from "./LoanAppChangeInterest";

import VisibilityIcon from "@assets/see_icon.svg";
import SeeOverIcon from "@assets/see_hover_icon.svg";
import CommentIcon from "@assets/empty_comments_icon.svg";
import WithComments from "@assets/with_comments_icon.svg";
import Person from "@assets/person_normal_icon.svg";
import AlertPerson from "@assets/person_lost_interest_icon.svg";
import RecoveryPerson from "@assets/person_recovery_interest_icon.svg";

interface LoanAppDataGridProps {
  refreshData: boolean;
  cols: Array<string>;
  applicationType: "Solicitudes" | "Perdieron interés" | "Rechazados" | "Autorizados" | "Clientes";
}

interface ApplicationRow {
  id: UUID;
  solicitudId: number;
  razonSocial: string;
  rfc: string;
  nombre: string;
  montoSolicitado: string;
  plazoSolicitado: number;
  montoContratado: string;
  plazoContratado: number;
  fechaRechazo: string;
  tiempoPantalla: number;
  tiempoTotal: number;
  tiempoTotalProceso: string;
  etapa: string;
  subEtapa: string;
  estatus: string;
  macroCanal: string;
  canal: string;
  asignacion: string;
  perdioInteres: number;
  dictamenCompletado: boolean;
  canalId: string;
  correoEjecutivo: string;
  fullData: string;
  tax_systems: {
    code: string;
  };
  totalComentarios: number;
  status_code: string;
  montoSolicitadoOriginal: number;
  plazoSolicitadoOriginal: number;
  usuario: {
    nombre: string;
    correo: string;
  };
  accionistaMayoritario: {
    nombre: string;
    rfc: string;
  };
  clientDetailId: UUID;
}

export const LoanAppDataGrid = (props: LoanAppDataGridProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const apiRef = useGridApiRef();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [childrenElement, setChildrenElement] = useState<ReactNode | null>(null);
  const [colorUsers, setColorUsers] = useState(new Map());

  const [rowLength, setRowLength] = useState<number>(0);
  const [rows, setRows] = useState<Array<ApplicationRow>>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 15,
    page: 0,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const stageColors = new Map([
    ["Registro", "#818181"],
    ["Solicitud", "#32C0A9"],
    ["Autorizado", "#1D806F"],
    ["Rechazado", "#EE7777"],
    ["Expediente", "#528CD6"],
    ["Visita", "#894DD8"],
    ["Contratación", "#6D36B4"],
    ["Cliente", "#002652"],
  ]);

  const columnsDef: GridColDef[] = [
    {
      headerName: "Id Solicitud",
      field: "solicitudId",
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
      renderCell: (row) => {
        return (
          <Link onClick={(event: React.SyntheticEvent<HTMLElement>) => handleOnClickLoanAppId(event, row)}>
            {row.value}
          </Link>
        );
      },
    },
    {
      headerName: "Razón Social / PFAE",
      field: "razonSocial",
      type: "string",
      align: "left",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      minWidth: 100,
      /*flex: 1,*/
    },
    {
      headerName: "RFC",
      field: "rfc",
      type: "string",
      align: "left",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      minWidth: 120,
    },
    {
      headerName: "Nombre Usuario",
      field: "nombre",
      type: "string",
      align: "left",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      minWidth: 120,
      maxWidth: 200,
      flex: 1,
    },
    {
      headerName: "Monto",
      field: "montoSolicitado",
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      minWidth: 120,
      maxWidth: 130,
      flex: 1,
    },
    {
      headerName: "Plazo",
      field: "plazoSolicitado",
      type: "number",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
    },
    {
      headerName: "Fecha Rechazo",
      field: "fechaRechazo",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
    },
    {
      headerName: "Tiempo en Estatus",
      field: "tiempoPantalla",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
      renderCell: (row) => {
        return formatHours(row.value);
      },
    },
    {
      headerName: "Tiempo Total",
      field: "tiempoTotal",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
      renderCell: (row) => {
        return formatHours(row.value);
      },
    },
    {
      headerName: props.applicationType === "Rechazados" ? "Etapa de Rechazo" : "Etapa",
      field: "etapa",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
      renderCell: (row) => {
        const textColor = stageColors.get(row.value);
        return (
          <Typography variant="body2" color={textColor} fontWeight={700}>
            {row.value}
          </Typography>
        );
      },
    },
    {
      headerName: props.applicationType === "Rechazados" ? "Sub Etapa de Rechazo" : "Sub Etapa",
      field: "subEtapa",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
    },
    {
      headerName: "Estatus",
      field: "estatus",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
    },
    {
      headerName: "Monto",
      field: "montoContratado",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
    },
    {
      headerName: "Plazo",
      field: "plazoContratado",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
    },
    {
      headerName: "Fecha Dispersión",
      field: "fechaDispersion",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
    },
    {
      headerName: "Tiempo Total Proceso",
      field: "tiempoTotalProceso",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
    },
    {
      headerName: "Macrocanal",
      field: "macroCanal",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
    },
    {
      headerName: "Canal",
      field: "canal",
      type: "string",
      align: "center",
      headerAlign: "center",
      cellClassName: "centering-adjustment",
      flex: 1,
      renderCell: (row) => {
        if (row.value !== "Orgánico") {
          return (
            <Link onClick={(event: React.SyntheticEvent<HTMLElement>) => handleOnClickLoanAppId(event, row)}>
              {row.value}
            </Link>
          );
        }
        return row.value;
      },
    },
    {
      headerName: "Asignación",
      field: "asignacion",
      type: "string",
      align: "left",
      headerAlign: "center",
      minWidth: 180,
      maxWidth: 250,
      flex: 1,
      renderCell: (row) => {
        if (row.value !== "") {
          let initials = "";

          const names = row.value.split(" ");
          initials = names[0].substring(0, 1) + names[1].substring(0, 1);

          return (
            <Box display={"flex"} alignItems={"center"} marginTop={"0.2em"}>
              <Avatar
                sx={{ width: 30, height: 30, marginRight: 1, bgcolor: colorUsers.get(row.value) }}
                alt={row.value}
              >
                <Typography fontSize={13}>{initials}</Typography>
              </Avatar>
              <Typography sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="body2">
                {row.value}
              </Typography>
            </Box>
          );
        }
        return row.value;
      },
    },
    {
      headerName: "Comentarios",
      field: "comentarios",
      align: "center",
      headerAlign: "center",
      flex: 1,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      renderCell: (row) => {
        return (
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={(event: React.SyntheticEvent<HTMLElement>) => handleOnClickLoanAppId(event, row)}
          >
            <img
              onMouseOver={(e) => (e.currentTarget.src = WithComments)}
              onMouseLeave={(e) => (e.currentTarget.src = row.row.totalComentarios > 0 ? WithComments : CommentIcon)}
              src={row.row.totalComentarios > 0 ? WithComments : CommentIcon}
            />
          </IconButton>
        );
      },
    },
    {
      headerName: "Detalle y Expediente",
      field: "expediente",
      align: "center",
      headerAlign: "center",
      flex: 1,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      renderCell: (row) => {
        return (
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={(event: React.SyntheticEvent<HTMLElement>) => handleOnClickLoanAppId(event, row)}
          >
            <img
              onMouseOver={(e) => (e.currentTarget.src = SeeOverIcon)}
              onMouseLeave={(e) => (e.currentTarget.src = VisibilityIcon)}
              src={VisibilityIcon}
            />
          </IconButton>
        );
      },
    },
    {
      headerName: "Interés",
      field: "perdioInteres",
      align: "center",
      headerAlign: "center",
      flex: 1,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      renderCell: (row) => {
        if (row.value === 0) {
          return (
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={(event: React.SyntheticEvent<HTMLElement>) => handleOnClickLoanAppId(event, row)}
            >
              <img
                onMouseOver={(e) => (e.currentTarget.src = AlertPerson)}
                onMouseLeave={(e) => (e.currentTarget.src = Person)}
                src={Person}
                alt="Normal"
                title="Normal"
              />
            </IconButton>
          );
        } else if (row.value === 1) {
          return (
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={(event: React.SyntheticEvent<HTMLElement>) => handleOnClickLoanAppId(event, row)}
            >
              <img
                onMouseOver={(e) => (e.currentTarget.src = RecoveryPerson)}
                onMouseLeave={(e) => (e.currentTarget.src = AlertPerson)}
                src={AlertPerson}
              />
            </IconButton>
          );
        } else if (row.value === 2) {
          return (
            <IconButton
              disableFocusRipple
              disableRipple
              onClick={(event: React.SyntheticEvent<HTMLElement>) => handleOnClickLoanAppId(event, row)}
            >
              <img
                onMouseOver={(e) => (e.currentTarget.src = AlertPerson)}
                onMouseLeave={(e) => (e.currentTarget.src = RecoveryPerson)}
                src={RecoveryPerson}
              />
            </IconButton>
          );
        }
      },
    },
  ];

  const columns = columnsDef.filter((tmp) => props.cols.some((col) => col === tmp.field));

  const statusResponse = useGetStatusQuery();
  const [triggerApplications, resultApplications] = useLazyGetApplicationsQuery();

  const applicationTransform = (data: Array<Application>) => {
    let colorsTmp = colorUsers;
    return data.map<ApplicationRow>((application, index) => {
      const randomColor = `#${(Math.random() * 0xfffff * 1000000).toString(16).slice(0, 6)}`;

      if (colorsTmp.get(application.code?.name ?? "") == undefined) {
        colorsTmp.set(application.code?.name, randomColor);
      }

      const accionistaMayoritario = application.person.find((p) => p.person_type?.code == "PTAM");

      const razonSocial =
        application.tax_systems.code === import.meta.env.VITE_CODE_TAXS_CS_PM
          ? application.client_details?.company_name
          : application.client_details?.name.concat(
              " ",
              application.client_details.last_name,
              " ",
              application.client_details.last_name_2,
            );

      const monto =
        application.loan_application_conditions[0].approved_amount === null
          ? application.loan_application_conditions[0].loan_amount
          : application.loan_application_conditions[0].approved_amount;
      const plazo =
        application.loan_application_conditions[0].approved_term === null
          ? application.loan_application_conditions[0].requested_term
          : application.loan_application_conditions[0].approved_term;

      const tmp: ApplicationRow = {
        id: application.id,
        solicitudId: application.id_application,
        razonSocial: razonSocial === null ? "-" : razonSocial,
        rfc: application.client_details?.company_rfc === null ? "-" : application.client_details?.company_rfc,
        nombre: application.client_details?.name.concat(
          " ",
          application.client_details.last_name,
          " ",
          application.client_details.last_name_2,
        ),
        montoSolicitado: formatCurrency(monto, "$", 0),
        plazoSolicitado: plazo,
        montoContratado: formatCurrency(application.loan_application_conditions[0].approved_amount, "$", 0),
        plazoContratado: application.loan_application_conditions[0].approved_term,
        fechaRechazo: application.last_status_change ? application.last_status_change.created_at.split("T")[0] : "",
        tiempoPantalla: application.status_time,
        tiempoTotal: application.total_time,
        tiempoTotalProceso: "2h, 15m",
        etapa: application.status_catalog.sub_stage.stage.name,
        subEtapa: application.status_catalog.sub_stage.name,
        estatus: application.status_catalog.name,
        macroCanal: application.source?.name ?? "Marketing",
        canal: application.medium?.name ?? "Orgánico",
        asignacion: application.code?.name ?? "Por asignar",
        perdioInteres: application.interested == undefined ? 0 : application.interested ? 2 : 1,
        dictamenCompletado: application.completed,
        canalId: application.code?.code ?? "",
        correoEjecutivo: application.code?.executive_collaborator_email ?? "",
        fullData: JSON.stringify(application),
        tax_systems: {
          code: application.tax_systems.code,
        },
        totalComentarios: application.total_comments,
        status_code: application.status_catalog.code,
        montoSolicitadoOriginal: application.loan_application_conditions[0].loan_amount,
        plazoSolicitadoOriginal: application.loan_application_conditions[0].requested_term,
        usuario: {
          nombre: application.client_details.name
            .concat(" ", application.client_details.last_name, " ", application.client_details.last_name_2)
            .trim(),
          correo: application.user.email,
        },
        accionistaMayoritario: {
          nombre:
            accionistaMayoritario?.name
              .concat(" ", accionistaMayoritario.last_name, " ", accionistaMayoritario.last_name_2)
              .trim() ?? "",
          rfc: accionistaMayoritario?.email ?? "",
        },
        clientDetailId: application.client_details_id,
      };

      setColorUsers(colorsTmp);

      return tmp;
    });
  };

  const updateApplications = async () => {
    if (props.applicationType == "Solicitudes") {
      const tmp = [
        "CSDUS",
        "CSRUS",
        "CSVTE",
        "CSDEM",
        "CSAEM",
        "CSUDC",
        "CSPDI",
        "CSDAL",
        "CSCDA",
        "CSDAV",
        "CSABA",
        "CSCOF",
        "CSAAO",
      ].map((code) => statusResponse.currentData?.data.data.find((status) => status.code === code)?.id);

      triggerApplications({
        size: paginationModel.pageSize,
        page: paginationModel.page + 1,
        q: "active:true",
        orderBy: `id_application:desc`,
        qin: tmp.map((t) => `qin=status_id:${t}`),
      });
    } else if (props.applicationType == "Perdieron interés") {
      triggerApplications({
        size: paginationModel.pageSize,
        page: paginationModel.page + 1,
        q: "interested:false",
        orderBy: undefined,
      });
    } else if (props.applicationType == "Rechazados") {
      const tmp = ["CSREC", "CSRDO"].map(
        (code) => statusResponse.currentData?.data.data.find((status) => status.code === code)?.id,
      );
      triggerApplications({
        size: paginationModel.pageSize,
        page: paginationModel.page + 1,
        q: undefined,
        orderBy: `id_application:desc`,
        qin: tmp.map((t) => `qin=status_id:${t}`),
      });
    } else if (props.applicationType == "Autorizados") {
      const tmp = [
        "CSEVE",
        "CSVEX",
        "CSAVC",
        "CSVYC",
        "CSVVC",
        "CSEVD",
        "CSFIR",
        "CSVCF",
        "CSDIS",
        "CSAAO",
        "CSBEX",
        "CSDIC",
        "CSDOC",
        "CSCDO",
        "CSDEM",
        "CSAED",
        "CSECI",
        "CSEDC",
        "CSEDV",
      ].map((code) => statusResponse.currentData?.data.data.find((status) => status.code === code)?.id);

      triggerApplications({
        size: paginationModel.pageSize,
        page: paginationModel.page + 1,
        q: undefined,
        orderBy: `id_application:desc`,
        qin: tmp.map((t) => `qin=status_id:${t}`),
      });
    } else if (props.applicationType == "Clientes") {
      const tmp = ["CSCLI"].map(
        (code) => statusResponse.currentData?.data.data.find((status) => status.code === code)?.id,
      );

      triggerApplications({
        size: paginationModel.pageSize,
        page: paginationModel.page + 1,
        q: undefined,
        orderBy: `id_application:desc`,
        qin: tmp.map((t) => `qin=status_id:${t}`),
      });
    }
  };

  const handleOnClickLoanAppId = async (event: React.SyntheticEvent<HTMLElement>, row: GridRenderCellParams) => {
    if (row.field === "solicitudId") {
      dispatch(setApplicationSelected(row.row));
      setChildrenElement(<LoanApplicationInfo />);
      setAnchorEl(event.currentTarget);
    } else if (row.field === "comentarios") {
      dispatch(setApplicationSelected(row.row));
      setChildrenElement(<LoanAppComments />);
      setAnchorEl(event.currentTarget);
    } else if (row.field === "perdioInteres") {
      if (row.value === 1) {
        setChildrenElement(
          <LoanAppChangeInterest applicationId={row.row.id} name={row.row.nombre} onUpdateRow={handleOnUpdateRow} />,
        );
      } else {
        setChildrenElement(<LoanAppLostInterest applicationId={row.row.id} onUpdateRow={handleOnUpdateRow} />);
      }

      setAnchorEl(event.currentTarget);
    } else if (row.field === "canal") {
      dispatch(setApplicationSelected(row.row));
      setChildrenElement(<LoanAppChannelInfo />);
      setAnchorEl(event.currentTarget);
    } else if (row.field === "expediente") {
      dispatch(setApplicationSelected(row.row));
      navigate("solicitud");
    }
  };

  const handleOnUpdateRow = (applicationId: UUID, attr: {}) => {
    setRows(
      rows.map((item) => {
        if (item.id === applicationId) {
          item = { ...item, ...attr };
        }

        return item;
      }),
    );

    setAnchorEl(null);

    updateApplications();
  };

  const handleOnClosePopup = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (resultApplications.isSuccess && statusResponse.isSuccess) {
      setRowLength(resultApplications.data.data.total);
      setRows(applicationTransform(resultApplications.data.data.data));

      apiRef.current.autosizeColumns();
    } else if (resultApplications.isError && resultApplications.error.status == 404) {
      setRowLength(0);
      setRows([]);
    }
  }, [resultApplications, statusResponse]);

  useEffect(() => {
    if (statusResponse.isSuccess) {
      updateApplications();
    }
  }, [paginationModel, statusResponse]);

  // useEffect(() => {
  //   console.log(sortModel)
  //   updateApplications();
  // }, [sortModel])

  useEffect(() => {
    if (props.refreshData && statusResponse.isSuccess) {
      updateApplications();
    }
  }, [props.refreshData]);

  return (
    <>
      <StripedDataGrid
        apiRef={apiRef}
        loading={resultApplications.isLoading}
        autosizeOptions={{
          expand: true,
          includeHeaders: true,
          includeOutliers: true,
        }}
        paginationMode="server"
        sortingMode="server"
        rowCount={rowLength}
        paginationModel={paginationModel}
        pageSizeOptions={[15, 30, 50, 100]}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        columns={columns}
        rows={rows}
        getRowId={(row) => row.solicitudId}
        getRowHeight={(_) => "auto"}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd")}
      />
      <CustomPopupModal anchorEl={anchorEl} children={childrenElement} onClose={handleOnClosePopup} showClose />
      {/* <CustomPopupModal anchorEl={anchorEl} onClose={handleOnClosePopup} >
        {childrenPopup === "AppInfo" && (
          <LoanApplicationInfo  />
        )}
      </CustomPopupModal> */}
    </>
  );
};
