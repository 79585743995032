import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { DocumentInfo } from "@interfaces/documents";
import { FileSectionV2 } from "@components/FileSectionV2";
import ModalSendToSign from "@components/ModalSendToSign";
import { CustomModal } from "@components/CustomModal";
import { generateDummyDocuments, getMimeTypeFromFilename } from "@helpers/generals";
// import { RejectDocumentDialogSegment } from "@components/RejectDocumentDialogSegment";
// import { ValidateDialogSegment } from "@components/ValidateDialogSegment";
import { usePostStatusApplicationMutation, usePutApplicationsMutation } from "@services/api/applications";
import { useGetFileCategoriesQuery, useLazyGetStatusQuery } from "@services/api/catalogs";
import {
  useGetUrlToDownloadDocMutation,
  useLazyGetContractDocsQuery,
  useLazyGetSignUrlToUploadDocQuery,
  usePatchContractDocMutation,
  usePostContractDocMutation,
  usePostUploadFileMutation,
} from "@services/api/documents";
import { DocumentAction } from "@store/slices/appSlice";

const CODES = {
  caratula: "CDDCA",
  contrato: "CDCON",
  pagare: "CDPAG",
  sol_disp: "CDSDD",
  tabla_amort: "CDTDA",
  autoriacion_domiciliacion: "CDADD",
  anexo_1: "ANX1",
  anexo_2: "ANX2",
};

export const DocsContractuales = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [childrenModal, setChildrenModal] = useState<"VALIDATE" | "REJECT" | "VALIDATE_SIGN" | "VALIDATE_TO_SIGN">();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [documentTriggered, setDocumentTriggered] = useState<DocumentInfo>();
  const [documentActionTriggered, setDocumentActionTriggered] = useState<DocumentAction>();
  const [enableSignClientValidate, setEnableSignClientValidate] = useState<boolean>(false);
  const [enableDispersion, setEnableDispersion] = useState<boolean>(false);

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const documentCategories = useGetFileCategoriesQuery();
  const [triggerGetDocuments, resultGetDocuments] = useLazyGetContractDocsQuery();
  const [triggerGetStatus] = useLazyGetStatusQuery();
  const [triggerPostUploadDoc] = usePostUploadFileMutation();
  const [triggerPutApplication] = usePutApplicationsMutation();
  const [triggerPostContractDoc] = usePostContractDocMutation();
  const [triggerPatchContractDoc] = usePatchContractDocMutation();
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();
  const [triggetPostUrlToUploadDoc] = useLazyGetSignUrlToUploadDocQuery();
  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const [documentToSign, setDocumentToSign] = useState<null | DocumentInfo>();
  const [docsContrato, setDocsContrato] = useState<DocumentInfo[]>([]);
  const [docsCaratula, setDocsCaratula] = useState<DocumentInfo[]>([]);
  const [docsPagare, setDocsPagare] = useState<DocumentInfo[]>([]);
  const [docsSolicitudDispersion, setDocsSolicitudDispersion] = useState<DocumentInfo[]>([]);
  const [docsTablaAmortizacion, setDocsTablaAmortizacion] = useState<DocumentInfo[]>([]);
  const [docsAutorizacionDomiciliacion, setDocsAutorizacionDomiciliacion] = useState<DocumentInfo[]>([]);
  const [docsAnexo1, setDocsAnexo1] = useState<DocumentInfo[]>([]);
  const [docsAnexo2, setDocsAnexo2] = useState<DocumentInfo[]>([]);

  const handleOnFileActionClicked = async (
    action: DocumentAction,
    file: DocumentInfo,
    newFile?: EventTarget & HTMLInputElement,
  ) => {
    console.log("action", action);

    if (action === DocumentAction.SEE) {
      const urlDownload = await triggerPostUrlDocument(file.url);

      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob], { type: getMimeTypeFromFilename(file.file_name) }));
      window.open(url);
    } else if (action === DocumentAction.DOWNLOAD) {
      const urlDownload = await triggerPostUrlDocument(file.url);
      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", file.file_name);

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } else if (action === DocumentAction.UPLOAD) {
      const tmpFile: any = newFile?.files?.[0];
      try {
        if (file.id === undefined) {
          file.client_details_id = JSON.parse(applicationSelected.fullData)["client_details_id"];
        }

        const urlToUploadResponse = await triggetPostUrlToUploadDoc({
          upload_type: "client",
          file_name: tmpFile?.name ?? "",
          regimen: "pm",
          client_details_id: file.client_details_id,
          file_category_id: file.file_category_id,
          loan_application_id: applicationSelected.id,
        }).unwrap();

        const signUrl = urlToUploadResponse.data;

        const tmp = new FormData();

        tmp.append("key", signUrl.fields.key);
        tmp.append("AWSAccessKeyId", signUrl.fields.AWSAccessKeyId);
        tmp.append("x-amz-security-token", signUrl.fields["x-amz-security-token"]);
        tmp.append("policy", signUrl.fields.policy);
        tmp.append("signature", signUrl.fields.signature);
        tmp.append("file", tmpFile);

        await triggerPostUploadDoc({
          url: signUrl.url,
          body: tmp,
        }).unwrap();

        if (file.id !== undefined) {
          await triggerPatchContractDoc({
            contractDocId: file.id,
            payload: {
              url: signUrl.fields.key,
            },
          });
        } else {
          await triggerPostContractDoc({
            loan_application_id: applicationSelected.id ?? "0-0-0-0-0",
            file_category_id: file.file_category_id ?? "0-0-0-0-0",
            url: signUrl.fields.key,
            client_details_id: file.client_details_id ?? "0-0-0-0-0",
            loan_id: applicationSelected.id ?? "0-0-0-0-0",
            file_name: tmpFile?.name ?? "",
          }).unwrap();
        }
      } catch (error) {
      } finally {
        tmpFile.value = "";

        await triggerGetDocuments({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
        });
      }
    } else if (action === DocumentAction.VALIDATE_TO_SIGN) {
    } else if (action === DocumentAction.REJECT_RELOAD) {
      // const tmpFile = newFile?.files?.[0];
      // try {
      //   if (file.id === undefined) {
      //     file.client_details_id = JSON.parse(applicationSelected.fullData)["client_details_id"];
      //   }
      //   const urlToUploadResponse = await triggetPostUrlToUploadDoc({
      //     upload_type: "client",
      //     file_name: tmpFile?.name ?? "",
      //     regimen: "pm",
      //     client_details_id: file.client_details_id,
      //     file_category_id: file.file_category_id,
      //     loan_application_id: applicationSelected.id,
      //   }).unwrap();
      //   const signUrl = urlToUploadResponse.data;
      //   const tmp = new FormData();
      //   tmp.append("key", signUrl.fields.key);
      //   tmp.append("AWSAccessKeyId", signUrl.fields.AWSAccessKeyId);
      //   tmp.append("x-amz-security-token", signUrl.fields["x-amz-security-token"]);
      //   tmp.append("policy", signUrl.fields.policy);
      //   tmp.append("signature", signUrl.fields.signature);
      //   if (tmpFile) {
      //     tmp.append("file", tmpFile);
      //   }
      //   await triggerPostUploadDoc({
      //     url: signUrl.url,
      //     body: tmp,
      //   }).unwrap();
      //   await triggerPatchContractDoc({
      //     contractDocId: file.id ?? "0-0-0-0-0",
      //     payload: {
      //       url: signUrl.fields.key,
      //       file_name: tmpFile?.name ?? "",
      //     },
      //   });
      // } catch (error) {
      // } finally {
      //   await triggerGetDocuments({
      //     applicationId: applicationSelected.id ?? "0-0-0-0-0",
      //     clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
      //   });
      // }
    } else if (action === DocumentAction.VALIDATE_SIGN) {
      setDocumentActionTriggered(action);
      setDocumentTriggered(file);
      setChildrenModal("VALIDATE_SIGN");
      setShowConfirmModal(true);
    } else if (action === DocumentAction.REJECT_SIGN) {
      // await triggerPatchContractDoc({
      //   contractDocId: file.id ?? "0-0-0-0-0",
      //   payload: {
      //     sign_validated: false,
      //   },
      // });
      // await triggerGetDocuments({
      //   applicationId: applicationSelected.id ?? "0-0-0-0-0",
      //   clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
      // });
    } else if (action === DocumentAction.SEND_MIFIEL) {
      setDocumentToSign(file);
    }
  };

  const handleOnModalBackClicked = () => {
    setShowConfirmModal(false);
  };

  const handleOnModalConfirmClicked = async () => {
    setIsProcessing(true);
    if (documentActionTriggered === DocumentAction.VALIDATE_TO_SIGN) {
      try {
        await triggerPatchContractDoc({
          contractDocId: documentTriggered?.id ?? "0-0-0-0-0",
          payload: {
            validated: true,
          },
        }).unwrap();

        await triggerGetDocuments({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
        });
      } catch (error) {
        console.log("Error al validar documento: %s", JSON.stringify(error));
      }
    } else if (documentActionTriggered === DocumentAction.REJECT_RELOAD) {
    } else if (documentActionTriggered === DocumentAction.VALIDATE_SIGN) {
      await triggerPatchContractDoc({
        contractDocId: documentTriggered?.id ?? "0-0-0-0-0",
        payload: {
          sign_validated: true,
        },
      });

      await triggerGetDocuments({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
      });
    }
    setShowConfirmModal(false);
    setIsProcessing(false);
  };

  const handleOnSignClientValidateClicked = async () => {
    await triggerPostStatusApplication({
      applicationId: applicationSelected.id ?? "0-0-0-0-0",
      statusCode: "CSFIR",
      disableSequenceCheck: true,
    }).unwrap();
  };

  const handleOnDispersionClicked = async () => {
    await triggerPostStatusApplication({
      applicationId: applicationSelected.id ?? "0-0-0-0-0",
      statusCode: "CSDIS",
      disableSequenceCheck: true,
    }).unwrap();
  };

  useEffect(() => {
    triggerGetDocuments({
      applicationId: applicationSelected.id ?? "0-0-0-0-0",
      clientDetailsId: JSON.parse(applicationSelected.fullData)["client_details_id"],
    });
  }, []);

  useEffect(() => {
    if (!resultGetDocuments.isSuccess) {
      return;
    }

    setEnableSignClientValidate(
      resultGetDocuments.currentData?.length == 8 &&
        (resultGetDocuments.currentData?.every((doc) => doc.validated === true) ?? false),
    );
    setEnableDispersion(
      resultGetDocuments.currentData?.length == 8 &&
        (resultGetDocuments.currentData?.every((doc) => doc.sign_user === true) ?? false),
    );

    let docs_caratula = resultGetDocuments.currentData?.filter((doc) => doc.category_code === CODES.caratula);
    let docs_contrato = resultGetDocuments.currentData?.filter((doc) => doc.category_code === CODES.contrato);
    let docs_pagare = resultGetDocuments.currentData?.filter((doc) => doc.category_code === CODES.pagare);
    let docs_solicitud = resultGetDocuments.currentData?.filter((doc) => doc.category_code === CODES.sol_disp);
    let docs_tabla = resultGetDocuments.currentData?.filter((doc) => doc.category_code === CODES.tabla_amort);
    let docs_autorizacion = resultGetDocuments.currentData?.filter((doc) => doc.category_code === "CDADD");
    let docs_anexo1 = resultGetDocuments.currentData?.filter((doc) => doc.category_code === CODES.anexo_1);
    let docs_anexo2 = resultGetDocuments.currentData?.filter((doc) => doc.category_code === CODES.anexo_2);

    if (docs_caratula?.length === 0) {
      const category = documentCategories.currentData?.find((category) => category.code === CODES.caratula);
      docs_caratula = generateDummyDocuments(1, category);
    }

    if (docs_contrato?.length === 0) {
      const category = documentCategories.currentData?.find((category) => category.code === CODES.contrato);
      docs_contrato = generateDummyDocuments(1, category);
    }

    if (docs_pagare?.length === 0) {
      const category = documentCategories.currentData?.find((category) => category.code === CODES.pagare);
      docs_pagare = generateDummyDocuments(1, category);
    }

    if (docs_solicitud?.length === 0) {
      const category = documentCategories.currentData?.find((category) => category.code === CODES.sol_disp);
      docs_solicitud = generateDummyDocuments(1, category);
    }

    if (docs_tabla?.length === 0) {
      const category = documentCategories.currentData?.find((category) => category.code === CODES.tabla_amort);
      docs_tabla = generateDummyDocuments(1, category);
    }

    if (docs_autorizacion?.length === 0) {
      const category = documentCategories.currentData?.find(
        (category) => category.code === CODES.autoriacion_domiciliacion,
      );
      docs_autorizacion = generateDummyDocuments(1, category);
    }

    if (docs_anexo1?.length === 0) {
      const category = documentCategories.currentData?.find((category) => category.code === CODES.anexo_1);
      docs_anexo1 = generateDummyDocuments(1, category);
    }

    if (docs_anexo2?.length === 0) {
      const category = documentCategories.currentData?.find((category) => category.code === CODES.anexo_2);
      docs_anexo2 = generateDummyDocuments(1, category);
    }

    setDocsCaratula(docs_caratula ?? []);
    setDocsContrato(docs_contrato ?? []);
    setDocsPagare(docs_pagare ?? []);
    setDocsSolicitudDispersion(docs_solicitud ?? []);
    setDocsTablaAmortizacion(docs_tabla ?? []);
    setDocsAutorizacionDomiciliacion(docs_autorizacion ?? []);
    setDocsAnexo1(docs_anexo1 ?? []);
    setDocsAnexo2(docs_anexo2 ?? []);
  }, [resultGetDocuments]);
  return (
    <>
      <Box margin={3}>
        <Typography variant="h4" fontWeight={600} color={"#002652"}>
          Contractuales
        </Typography>
        <FileSectionV2
          title={"Caratula"}
          documents={docsCaratula}
          onFileActionClick={handleOnFileActionClicked}
          showHeaders
          showClientSign
          showInternalSign
          showSendMifiel
        />
        <FileSectionV2
          title={"Contrato"}
          documents={docsContrato}
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showSendMifiel
        />
        <FileSectionV2
          title={"Pagaré"}
          documents={docsPagare}
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showSendMifiel
        />
        <FileSectionV2
          title={"Solicitud de disposición"}
          documents={docsSolicitudDispersion}
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showSendMifiel
        />
        <FileSectionV2
          title={"Tabla de amortización"}
          documents={docsTablaAmortizacion}
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showSendMifiel
        />
        <FileSectionV2
          title={"Autorización de domiciliación"}
          documents={docsAutorizacionDomiciliacion}
          onFileActionClick={handleOnFileActionClicked}
          showClientSign
          showInternalSign
          showSendMifiel
        />
        {applicationSelected.tax_systems.code !== "CS002" && (
          <FileSectionV2
            title={"Aval - Anexo 1"}
            documents={docsAnexo1}
            onFileActionClick={handleOnFileActionClicked}
            showClientSign
            showInternalSign
            showRejectSign
            showValidateSign
            showSendMifiel
          />
        )}
        {applicationSelected.tax_systems.code !== "CS002" && (
          <FileSectionV2
            title={"Propietario real - Anexo 2"}
            documents={docsAnexo2}
            onFileActionClick={handleOnFileActionClicked}
            showClientSign
            showInternalSign
            showRejectSign
            showValidateSign
            showSendMifiel
          />
        )}
      </Box>

      <Box mt={10} display={"flex"} justifyContent={"flex-end"} columnGap={2}>
        <Button variant="zomp" onClick={handleOnSignClientValidateClicked} /*disabled={!enableSignClientValidate}*/>
          Validar para firma Cliente
        </Button>
        {/* <Button variant="zomp" onClick={handleOnDispersionClicked} disabled={!enableDispersion}>
          Liberar dispersión
        </Button> */}
      </Box>

      {documentToSign && (
        <ModalSendToSign
          document={documentToSign}
          onClose={() => {
            setDocumentToSign(null);
          }}
        />
      )}
      {/* <CustomModal open={showConfirmModal}>
        {childrenModal === "VALIDATE" && (
          <ValidateDialogSegment
            type={"DOCVALIDATE"}
            title={`Estas seguro de validar el documento ${documentTriggered?.category_name} de:`}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onContinueClick={handleOnModalConfirmClicked}
          />
        )}
        {childrenModal === "VALIDATE_SIGN" && (
          <ValidateDialogSegment
            type={"DOCVALIDATE"}
            title={`Estas seguro de validar firma del documento ${documentTriggered?.category_name} de:`}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onContinueClick={handleOnModalConfirmClicked}
          />
        )}
        {childrenModal === "REJECT" && (
          <RejectDocumentDialogSegment
            document={documentTriggered}
            isProcessing={isProcessing}
            onBackClick={handleOnModalBackClicked}
            onConfirmClick={handleOnModalConfirmClicked}
          />
        )}
      </CustomModal> */}
    </>
  );
};
