import { useState } from "react";

import { CustomTabPanel, CustomTabs } from "@components/CustomTabs";
import { Perfil } from "@components/SolicitudSegmentos/PerfilEmpresa/Perfil";
import { General } from "@components/SolicitudSegmentos/PerfilEmpresa/General";
import { Dashboard } from "@components/SolicitudSegmentos/PerfilEmpresa/Dashboard";
import { Financieros } from "@components/SolicitudSegmentos/PerfilEmpresa/Financieros";

export const PerFilEmpresa = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleOnChangeTab = async (_: string, indexTab: number) => {
    setCurrentTab(indexTab);
  };

  return (
    <>
      <CustomTabs
        titleTabs={["General", "Perfil", "Dashboard", "Financieros"]}
        onChangeTab={handleOnChangeTab}
      ></CustomTabs>
      <CustomTabPanel index={0} value={currentTab}>
        <General />
      </CustomTabPanel>
      <CustomTabPanel index={1} value={currentTab}>
        <Perfil />
      </CustomTabPanel>
      <CustomTabPanel index={2} value={currentTab}>
        <Dashboard />
      </CustomTabPanel>
      <CustomTabPanel index={3} value={currentTab}>
        <Financieros />
      </CustomTabPanel>
    </>
  );
};
