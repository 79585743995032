import { CustomTabs, CustomTabPanel } from "@components/CustomTabs";
import { DocsContractuales } from "@components/SolicitudSegmentos/Contractuales/DocsContractuales";

export const Contractuales = () => {
  return (
    <>
      <CustomTabs titleTabs={["Documentos Contractuales"]}></CustomTabs>
      <CustomTabPanel index={0} value={0}>
        <DocsContractuales />
      </CustomTabPanel>
    </>
  );
};
