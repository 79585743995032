import { Typography } from "@mui/material";

import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CategoryScale, ChartData, ChartOptions } from "chart.js";

import { formatCurrency } from "@helpers/formats";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

interface GraficoBarrasGenericoProps {
  title: string;
  subtitle?: string;
  data: Array<Array<number>>;
  labels: Array<string>;
  colors: Array<string>;
  stacked?: boolean;
  horizontalBars?: boolean;
}

export const GraficoBarrasGenerico = (props: GraficoBarrasGenericoProps) => {
  const data: ChartData<"bar"> = {
    labels: props.labels,
    datasets: props.data.map((tmp, idx) => {
      return {
        axis: props.horizontalBars ? "y" : "x",
        data: tmp,
        backgroundColor: props.colors[idx],
      };
    }),
  };

  const options: ChartOptions<"bar"> = {
    maintainAspectRatio: false,
    aspectRatio: 1,
    indexAxis: props.horizontalBars ? "y" : "x",
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: props.stacked ?? false,
        display: !props.horizontalBars,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: props.stacked ?? false,
        beginAtZero: true,
        grid: {
          display: !props.horizontalBars,
        },
        ticks: {
          // color: "rgb(0, 38, 84)",
          callback: (value, _index, _ticks) => {
            if (props.horizontalBars) {
              return props.labels[_index];
            }
            return formatCurrency(Number(value), "$", 0);
          },
          // font: {
          //   size: 14,
          //   weight: "bold",
          // },
        },
      },
    },
  };
  return (
    <>
      <Typography variant="h3" fontWeight={700} color="#002652" mb={2}>
        {props.title}
        <Typography component={"span"} variant="h5" fontWeight={400} color="#002652">
          {" "}
          {props.subtitle}
        </Typography>
      </Typography>
      <div className="chart-container" style={{ position: "relative", height: "25vh" }}>
        <Bar data={data} options={options} />
      </div>
    </>
  );
};
