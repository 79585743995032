import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Alert } from "@mui/material";

import { RootState, store } from "@store/store";
import { FileSectionV2 } from "@components/FileSectionV2";
import { DocumentInfo } from "@interfaces/documents";
import { generateDummyDocuments, getMimeTypeFromFilename } from "@helpers/generals";
import { useGetFileCategoriesQuery, useLazyGetStatusQuery } from "@services/api/catalogs";
import { usePutApplicationsMutation, usePostStatusApplicationMutation } from "@services/api/applications";

import { usePatchPersonMutation } from "@services/api/persons";
import {
  useLazyGetDocumentsByAppQuery,
  usePostDocumentMutation,
  usePatchDocumentMutation,
  usePostUploadFileMutation,
  useGetUrlToDownloadDocMutation,
  useLazyGetSignUrlToUploadDocQuery,
} from "@services/api/documents";
import {
  setShareHolderDocsValidated,
  setOwnersDocsValidated,
  DocumentAction,
  setDocumentAction,
  setDialogConfig,
  DialogType,
} from "@store/slices/appSlice";

export const AvalPropietarios = () => {
  const dispatch = useDispatch();

  const documentAction = (state = store.getState()) => state.app.components.documentAction;
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const documentCategories = useGetFileCategoriesQuery();

  const [triggerGetDocuments, resultGetDocuments] = useLazyGetDocumentsByAppQuery({
    selectFromResult: ({ data }) => ({
      documents: data?.data,
    }),
  });

  const [triggerGetStatus] = useLazyGetStatusQuery();
  const [triggerPatchPerson] = usePatchPersonMutation();
  const [triggerPostDocument] = usePostDocumentMutation();
  const [triggerPatchDocument] = usePatchDocumentMutation();
  const [triggerPostUploadDoc] = usePostUploadFileMutation();
  const [triggerPutApplication] = usePutApplicationsMutation();
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();
  const [triggetPostUrlToUploadDoc] = useLazyGetSignUrlToUploadDocQuery();
  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const handleOnModalConfirmClicked = async () => {
    const docAction = documentAction();
    if (docAction.action === DocumentAction.REJECT) {
      const statusCatalog = await triggerGetStatus();
      const currentStatusId = JSON.parse(applicationSelected.fullData)["status_id"];
      const currentStatusCode = statusCatalog?.data?.data.data.find((item) => item.id === currentStatusId)?.code;

      if (currentStatusCode === "CSEDC") {
        await triggerPostStatusApplication({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          statusCode: "CSECI",
          disableSequenceCheck: true,
        }).unwrap();
      }

      const information_person_completed = ["DIO", "DCDF", "DCD"].find((doc) => doc === docAction.doc?.category_code)
        ? false
        : null;
      const bank_references_completed = ["DECB"].find((doc) => doc === docAction.doc?.category_code) ? false : null;

      if (information_person_completed === false || bank_references_completed === false) {
        await triggerPatchPerson({
          personId: docAction.doc?.person_id ?? "0-0-0-0-0",
          body: {
            information_person_completed,
            bank_references_completed,
          },
        });

        await triggerPutApplication({
          applicationId: applicationSelected.id,
          body: {
            completed: false,
          },
        });
      }

      await triggerGetDocuments({ applicationId: applicationSelected.id, type: "shareholders_owners" });
    } else if (docAction.action === DocumentAction.VALIDATE) {
      try {
        await triggerPatchDocument({
          applicationId: applicationSelected.id,
          documentId: docAction.doc?.id ?? "0-0-0-0-0",
          body: {
            validated: true,
          },
        }).unwrap();

        await triggerGetDocuments({ applicationId: applicationSelected.id, type: "shareholders_owners" });
      } catch (error) {
        console.log("Error al validar documento: %s", error);
      }
    }
    dispatch(
      setDialogConfig({
        shown: false,
        type: DialogType.NONE,
        props: undefined,
      }),
    );
  };

  const handleOnFileActionClicked = async (
    action: DocumentAction,
    file: DocumentInfo,
    newFile?: EventTarget & HTMLInputElement,
  ) => {
    if (action === DocumentAction.SEE) {
      const urlDownload = await triggerPostUrlDocument(file.url);

      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob], { type: getMimeTypeFromFilename(file.file_name) }));
      window.open(url);
    } else if (action === DocumentAction.DOWNLOAD) {
      const urlDownload = await triggerPostUrlDocument(file.url);
      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", file.file_name);

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } else if (action === DocumentAction.UPLOAD) {
      const tmpFile = newFile?.files?.[0];
      try {
        if (file.id === undefined) {
          file.client_details_id = JSON.parse(applicationSelected.fullData)["client_details_id"];
        }

        const urlToUploadResponse = await triggetPostUrlToUploadDoc({
          upload_type: "client",
          file_name: tmpFile?.name ?? "",
          regimen: "pm",
          person_id: file.person_id,
          client_details_id: file.client_details_id,
          file_category_id: file.file_category_id,
          loan_application_id: applicationSelected.id,
        }).unwrap();

        const signUrl = urlToUploadResponse.data;

        const tmp = new FormData();

        tmp.append("key", signUrl.fields.key);
        tmp.append("AWSAccessKeyId", signUrl.fields.AWSAccessKeyId);
        tmp.append("x-amz-security-token", signUrl.fields["x-amz-security-token"]);
        tmp.append("policy", signUrl.fields.policy);
        tmp.append("signature", signUrl.fields.signature);
        tmp.append("file", tmpFile);

        await triggerPostUploadDoc({
          url: signUrl.url,
          body: tmp,
        }).unwrap();

        if (file.id !== undefined) {
          await triggerPatchDocument({
            applicationId: applicationSelected.id,
            documentId: file.id,
            body: {
              status: false,
            },
          }).unwrap();
        }

        await triggerPostDocument({
          loan_application_id: applicationSelected.id ?? "0-0-0-0-0",
          file_category_id: file.file_category_id ?? "0-0-0-0-0",
          url: signUrl.fields.key,
          file_name: tmpFile?.name ?? "",
          // client_details_id: file.client_details_id,
          person_id: file.person_id,
        }).unwrap();
      } catch (error) {
      } finally {
        tmpFile.value = "";
        await triggerGetDocuments({ applicationId: applicationSelected.id, type: "shareholders_owners" });
      }
    } else if (action === DocumentAction.VALIDATE) {
      dispatch(
        setDocumentAction({
          action,
          doc: file,
        }),
      );
      dispatch(
        setDialogConfig({
          shown: true,
          type: DialogType.VALIDAR_DOC,
          props: {
            title: `Estas seguro de validar el documento ${file.category_name} de:`,
          },
          onAcceptClick: async () => {
            await handleOnModalConfirmClicked();
          },
        }),
      );
    } else if (action === DocumentAction.REJECT) {
      dispatch(
        setDocumentAction({
          action,
          doc: file,
        }),
      );
      dispatch(
        setDialogConfig({
          shown: true,
          type: DialogType.RECHAZAR_DOC,
          props: {
            document: file,
          },
          onAcceptClick: handleOnModalConfirmClicked,
        }),
      );
    }
  };

  useEffect(() => {
    const shareholder_docs_gral_validated =
      resultGetDocuments.documents?.shareholders
        ?.map(
          (shareholder) =>
            ["DIO", "ANX1"]
              .map((code) => shareholder.grals.some((doc) => doc.category_code == code && doc.validated))
              .every((flag) => flag === true) ?? false,
        )
        .every((t) => t === true) ?? false;

    // const shareholder_docs_gral_completed =
    //   resultGetDocuments.documents?.shareholders
    //     ?.map((shareholder) => shareholder.grals.length >= 3)
    //     .every((t) => t === true) ?? false;

    const shareholder_docs_gral_completed = true;

    const shareholder_docs_account_validated =
      resultGetDocuments.documents?.shareholders
        ?.map((shareholder) =>
          shareholder.accounts
            .map((account) => account.bank_statements.every((statement) => statement.validated))
            .every((t) => t === true),
        )
        .every((t) => t === true) ?? false;

    const shareholder_docs_account_completed =
      resultGetDocuments.documents?.shareholders
        ?.map((shareholder) => {
          if (shareholder.accounts.length >= 1) {
            return shareholder.accounts
              .map((account) => account.bank_statements.every((statement) => statement.validated))
              .every((t) => t === true);
          } else {
            return false;
          }
        })
        .every((t) => t === true) ?? false;

    console.log("shareholder_docs_gral_validated: ", shareholder_docs_gral_validated);
    console.log("shareholder_docs_gral_completed: ", shareholder_docs_gral_completed);
    console.log("shareholder_docs_account_validated: ", shareholder_docs_account_validated);
    console.log("shareholder_docs_account_completed: ", shareholder_docs_account_completed);

    dispatch(
      setShareHolderDocsValidated(
        shareholder_docs_gral_validated &&
          shareholder_docs_gral_completed &&
          shareholder_docs_account_validated &&
          shareholder_docs_account_completed,
      ),
    );

    const owners_docs_validated =
      resultGetDocuments.documents?.owners
        ?.map(
          (owner) =>
            ["DIO", "ANX2"]
              .map((code) => owner.grals.some((doc) => doc.category_code == code && doc.validated))
              .every((flag) => flag === true) ?? false,
        )
        // ?.map((owner) => owner.grals.every((doc) => doc.validated))
        .every((t) => t === true) ?? false;

    // const owners_docs_completed =
    //   resultGetDocuments.documents?.owners?.map((owner) => owner.grals.length >= 3).every((t) => t === true) ?? false;

    const owners_docs_completed = true;

    console.log("owners: ", owners_docs_validated && owners_docs_completed);

    dispatch(setOwnersDocsValidated(owners_docs_validated && owners_docs_completed));
  }, [resultGetDocuments]);

  useEffect(() => {
    triggerGetDocuments({
      applicationId: applicationSelected.id,
      type: "shareholders_owners",
    });
  }, []);

  return (
    <>
      <Box margin={3}>
        {resultGetDocuments.documents?.shareholders?.map((shareholder, index) => (
          <Box key={index}>
            <Typography variant="h4" fontWeight={600} color={"#002652"}>
              Expediente Aval: {shareholder.fullname}
            </Typography>
            <FileSectionV2
              showValidate
              showReject
              title={"Identificación"}
              documents={
                shareholder.grals.some((item) => item.category_code === "DIO")
                  ? shareholder.grals.filter((item) => item.category_code === "DIO")
                  : generateDummyDocuments(
                      1,
                      documentCategories.currentData?.find((category) => category.code === "DIO"),
                      shareholder.person_id,
                    )
              }
              onFileActionClick={handleOnFileActionClicked}
              showHeaders
            />
            <FileSectionV2
              showValidate
              showReject
              title={"Verificación de identidad"}
              documents={
                shareholder.grals.some((item) => item.category_code === "VIDME")
                  ? shareholder.grals.filter((item) => item.category_code === "VIDME")
                  : generateDummyDocuments(
                      1,
                      documentCategories.currentData?.find((category) => category.code === "VIDME"),
                      shareholder.person_id,
                    )
              }
              onFileActionClick={handleOnFileActionClicked}
              showHeaders
            />
            <FileSectionV2
              showValidate
              showReject
              title={"Constancia de situación fiscal"}
              documents={
                shareholder.grals.some((item) => item.category_code === "DCDF")
                  ? shareholder.grals.filter((item) => item.category_code === "DCDF")
                  : generateDummyDocuments(
                      1,
                      documentCategories.currentData?.find((category) => category.code === "DCDF"),
                      shareholder.person_id,
                    )
              }
              onFileActionClick={handleOnFileActionClicked}
            />
            <FileSectionV2
              showValidate
              showReject
              title={"Comprobante de domicilio"}
              documents={
                shareholder.grals.some((item) => item.category_code === "DCD")
                  ? shareholder.grals.filter((item) => item.category_code === "DCD")
                  : generateDummyDocuments(
                      1,
                      documentCategories.currentData?.find((category) => category.code === "DCD"),
                      shareholder.person_id,
                    )
              }
              onFileActionClick={handleOnFileActionClicked}
            />
            <FileSectionV2
              showValidate
              showReject
              title={"Anexo 1 - Información del aval"}
              documents={
                shareholder.grals.some((item) => item.category_code === "ANX1")
                  ? shareholder.grals.filter((item) => item.category_code === "ANX1")
                  : generateDummyDocuments(
                      1,
                      documentCategories.currentData?.find((category) => category.code === "ANX1"),
                      shareholder.person_id,
                    )
              }
              onFileActionClick={handleOnFileActionClicked}
            />
            {shareholder.accounts.map((account, index) => (
              <FileSectionV2
                showValidate
                showReject
                key={index}
                title={`Información bancaria`}
                documents={account.bank_statements}
                extras={[
                  { title: "Cuenta CLABE", value: account.clabe },
                  { title: "Banco", value: account.bank_name },
                ]}
                onFileActionClick={handleOnFileActionClicked}
              />
            ))}
            {shareholder.accounts.length === 0 && (
              <Alert sx={{ my: 2 }} severity="error">
                Falta información bancaria del aval
              </Alert>
            )}
          </Box>
        ))}
        {resultGetDocuments.documents?.owners?.map((owner, index) => (
          <Box key={index}>
            <Typography variant="h4" fontWeight={600} color={"#002652"} marginTop={4}>
              Propietario Real: {owner.fullname}
            </Typography>

            <FileSectionV2
              showValidate
              showReject
              title={"Identificación"}
              documents={
                owner.grals.some((item) => item.category_code === "DIO")
                  ? owner.grals.filter((item) => item.category_code === "DIO")
                  : generateDummyDocuments(
                      1,
                      documentCategories.currentData?.find((category) => category.code === "DIO"),
                      owner.person_id,
                    )
              }
              onFileActionClick={handleOnFileActionClicked}
              showHeaders
            />
            <FileSectionV2
              showValidate
              showReject
              title={"Constancia de situación fiscal"}
              documents={
                owner.grals.some((item) => item.category_code === "DCDF")
                  ? owner.grals.filter((item) => item.category_code === "DCDF")
                  : generateDummyDocuments(
                      1,
                      documentCategories.currentData?.find((category) => category.code === "DCDF"),
                      owner.person_id,
                    )
              }
              onFileActionClick={handleOnFileActionClicked}
            />
            <FileSectionV2
              showValidate
              showReject
              title={"Anexo 2 - Información del Propietario"}
              documents={
                owner.grals.some((item) => item.category_code === "ANX2")
                  ? owner.grals.filter((item) => item.category_code === "ANX2")
                  : generateDummyDocuments(
                      1,
                      documentCategories.currentData?.find((category) => category.code === "ANX2"),
                      owner.person_id,
                    )
              }
              onFileActionClick={handleOnFileActionClicked}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};
