import {
  GridActionsCellItem,
  GridColDef,
  GridEditBooleanCell,
  GridEditInputCell,
  GridEventListener,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridValidRowModel,
  useGridApiRef,
} from "@mui/x-data-grid";
import { StripedDataGrid } from "./CustomDataGrid";
import { UUID } from "crypto";
import { Box, Button, Checkbox, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import {
  useDeletePersonMutation,
  useLazyGetPersonsQuery,
  usePatchPersonMutation,
  usePostPersonMutation,
} from "@services/api/persons";
import { useGetPersonTypesQuery } from "@services/api/catalogs";
import { formatCurrency } from "@helpers/formats";

export interface ShareholderRow {
  id: UUID;
  name: string;
  firstLastName: string;
  secondLastName: string;
  totalValue: number;
  percent: number;
  legalAgent: boolean;
  majorityShareholder: boolean;
  actualOwner: boolean;
  isNew?: boolean;
}

const EditInputCell = (props: GridRenderEditCellParams) => {
  const { error } = props;
  return (
    <Box border={error ? "1px solid red" : ""}>
      <GridEditInputCell {...props} />
    </Box>
  );
};

export const ShareholderStructure = () => {
  const apiRef = useGridApiRef();

  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [shareHolders, setShareHolders] = useState<Array<ShareholderRow>>([]);

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const { data: personTypes, isSuccess: personTypesFilled } = useGetPersonTypesQuery();

  const [triggerGetPersons, getPersonsStatus] = useLazyGetPersonsQuery();
  const [triggerPostPerson] = usePostPersonMutation();
  const [triggerPatchPerson] = usePatchPersonMutation();
  const [triggerDeletePerson] = useDeletePersonMutation();

  const columnsDef: GridColDef[] = [
    {
      headerName: "Nombre",
      field: "name",
      align: "center",
      headerAlign: "center",
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      editable: !applicationSelected.dictamenCompletado,
      flex: 1,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value.length == 0;
        return { ...params.props, error: hasError };
      },
      renderEditCell: (props: GridRenderEditCellParams) => {
        const { error } = props;
        return (
          <Box border={error ? "1px solid red" : ""}>
            <GridEditInputCell {...props} />
          </Box>
        );
      },
    },
    {
      headerName: "Apellido Paterno",
      field: "firstLastName",
      align: "center",
      headerAlign: "center",
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      editable: !applicationSelected.dictamenCompletado,
      flex: 1,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value.length == 0;
        return { ...params.props, error: hasError };
      },
      renderEditCell: (props: GridRenderEditCellParams) => {
        const { error } = props;
        return (
          <Box border={error ? "1px solid red" : ""}>
            <GridEditInputCell {...props} />
          </Box>
        );
      },
    },
    {
      headerName: "Apellido Materno",
      field: "secondLastName",
      align: "center",
      headerAlign: "center",
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      editable: !applicationSelected.dictamenCompletado,
      flex: 1,
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <EditInputCell {...params} />;
      },
    },
    {
      headerName: "Valor Total",
      field: "totalValue",
      align: "center",
      type: "number",
      headerAlign: "center",
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      editable: !applicationSelected.dictamenCompletado,
      flex: 1,
      valueFormatter: (value) => {
        if (value == null || value == 0) {
          return "$0";
        }

        return formatCurrency(Number(value), "$", 0);
      },
    },
    {
      headerName: "Porcentaje",
      field: "percent",
      align: "center",
      type: "number",
      headerAlign: "center",
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      editable: !applicationSelected.dictamenCompletado,
      flex: 1,
      renderEditCell: (props: GridRenderEditCellParams) => {
        return <GridEditInputCell {...props} inputProps={{ max: 100, min: 0 }} />;
      },
      valueFormatter: (value) => {
        if (value == null) {
          return "0%";
        }

        return `${value}%`;
      },
    },
    {
      headerName: "Representante Legal",
      field: "legalAgent",
      type: "boolean",
      headerAlign: "center",
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      editable: !applicationSelected.dictamenCompletado,
      flex: 1,
      renderCell: (row) => {
        return <Checkbox checked={row.value} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { error } = params;
        let exists = shareHolders.find((item) => item.legalAgent === true);

        exists = exists?.id === params.row.id ? undefined : exists;

        return (
          <Box sx={{ ".MuiDataGrid-editBooleanCell .MuiButtonBase-root": { color: error ? "red" : "" } }}>
            {exists === undefined && (
              <GridEditBooleanCell {...params} onChange={() => handleOnPersonTypeChange(params)} />
            )}
          </Box>
        );
      },
    },
    {
      headerName: "Accionista mayoritario",
      field: "majorityShareholder",
      type: "boolean",
      headerAlign: "center",
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      editable: !applicationSelected.dictamenCompletado,
      flex: 1,
      renderCell: (row) => {
        return <Checkbox checked={row.value} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { error } = params;

        let exists = shareHolders.find((item) => item.majorityShareholder === true);
        exists = exists?.id === params.row.id ? undefined : exists;

        return (
          <Box sx={{ ".MuiDataGrid-editBooleanCell .MuiButtonBase-root": { color: error ? "red" : "" } }}>
            {exists === undefined && (
              <GridEditBooleanCell {...params} onChange={() => handleOnPersonTypeChange(params)} />
            )}
          </Box>
        );
      },
    },
    {
      headerName: "Propietario real",
      field: "actualOwner",
      type: "boolean",
      headerAlign: "center",
      hideSortIcons: true,
      disableColumnMenu: true,
      disableExport: true,
      disableReorder: true,
      editable: !applicationSelected.dictamenCompletado,
      flex: 1,
      renderCell: (row) => {
        return <Checkbox checked={row.value} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { error } = params;

        return (
          <Box sx={{ ".MuiDataGrid-editBooleanCell .MuiButtonBase-root": { color: error ? "red" : "" } }}>
            <GridEditBooleanCell {...params} onChange={() => handleOnPersonTypeChange(params)} />
          </Box>
        );
      },
    },
    {
      headerName: "Acciones",
      field: "acciones",
      type: "actions",
      width: 100,
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode || row.isNew) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label={"Guardar"} onClick={() => handleOnSave(id)} />,
            <GridActionsCellItem icon={<CancelIcon />} label={"Cancelar"} onClick={() => handleOnCancel(id)} />,
          ];
        } else {
          return [
            <GridActionsCellItem icon={<EditIcon />} label={"Editar"} onClick={() => handleOnEdit(id)} />,
            <GridActionsCellItem icon={<DeleteIcon />} label={"Borrar"} onClick={() => handleOnDelete(id)} />,
          ];
        }
      },
    },
  ];

  const handleOnPersonTypeChange = (params: GridRenderEditCellParams) => {
    ["actualOwner", "majorityShareholder", "legalAgent"]
      .filter((item) => item !== params.field)
      .forEach((item) => {
        apiRef.current.setEditCellValue({
          id: params.id,
          field: item,
          value: false,
        });
      });
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleOnClickAddShareHolder = () => {
    setEditEnabled(true);

    setShareHolders([
      ...shareHolders,
      {
        id: crypto.randomUUID(),
        name: "",
        firstLastName: "",
        secondLastName: "",
        totalValue: 0,
        percent: 0,
        legalAgent: false,
        majorityShareholder: false,
        actualOwner: false,
        isNew: true,
      },
    ]);
  };

  const handleOnSave = (id: GridRowId) => {
    apiRef.current.stopRowEditMode({ id });

    setEditEnabled(false);
  };

  const handleOnCancel = (id: GridRowId) => {
    apiRef.current.stopRowEditMode({ id, ignoreModifications: true });

    const editedRow = shareHolders.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setShareHolders(shareHolders.filter((row) => row.id !== id));
    }

    setEditEnabled(false);
  };

  const handleOnEdit = (id: GridRowId) => {
    setEditEnabled(true);
    apiRef.current.startRowEditMode({ id });
  };

  const handleOnDelete = async (id: GridRowId) => {
    await triggerDeletePerson(id.toString());
    setShareHolders(shareHolders.filter((item) => item.id !== id));
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleProcessRowUpdated = async (newRow: GridValidRowModel, _: GridValidRowModel) => {
    let personTypeId: UUID | undefined;
    if (newRow["legalAgent"]) {
      personTypeId = personTypes?.find((type) => type.code === "PTRL")?.id;
    } else if (newRow["majorityShareholder"]) {
      personTypeId = personTypes?.find((type) => type.code === "PTAM")?.id;
    } else if (newRow["actualOwner"]) {
      personTypeId = personTypes?.find((type) => type.code === "PTPR")?.id;
    } else {
      personTypeId = personTypes?.find((type) => type.code === "PTACAV")?.id;
    }

    if (newRow.isNew) {
      await triggerPostPerson({
        name: newRow["name"],
        last_name: newRow["firstLastName"],
        last_name_2: newRow["secondLastName"],
        porcent: newRow["percent"],
        total_value: newRow["totalValue"],
        person_type_id: personTypeId ?? "0-0-0-0-0",
        loan_application_id: applicationSelected.id ?? "0-0-0-0-0",
      });
    } else {
      await triggerPatchPerson({
        personId: newRow["id"],
        body: {
          name: newRow["name"],
          last_name: newRow["firstLastName"],
          last_name_2: newRow["secondLastName"],
          porcent: newRow["percent"],
          total_value: newRow["totalValue"],
          person_type_id: personTypeId ?? "0-0-0-0-0",
          loan_application_id: applicationSelected.id ? applicationSelected.id : "0-0-0-0-0",
        },
      });
    }

    await triggerGetPersons({ q: `loan_application_id:${applicationSelected.id}` });
    return newRow;
  };

  useEffect(() => {
    shareHolders.forEach((row) =>
      row.isNew && rowModesModel[row.id]?.mode !== GridRowModes.Edit
        ? apiRef.current.startRowEditMode({ id: row.id })
        : "",
    );
  }, [shareHolders]);

  useEffect(() => {
    triggerGetPersons({ q: `loan_application_id:${applicationSelected.id}` });
  }, []);

  useEffect(() => {
    triggerGetPersons({ q: `loan_application_id:${applicationSelected.id}` });
  }, [applicationSelected]);

  useEffect(() => {
    if (getPersonsStatus.isSuccess && getPersonsStatus.currentData && personTypesFilled) {
      const types = personTypes.filter((type) => ["PTAM", "PTPR", "PTRL", "PTACAV"].indexOf(type.code) > -1);
      const typesIds = types.map<UUID>((type) => type.id);

      const personFiltered = getPersonsStatus.data
        .filter((person) => typesIds.indexOf(person.person_type_id) > -1)
        .map<ShareholderRow>((person) => {
          const type = personTypes.filter((type) => type.id == person.person_type_id)[0];
          return {
            id: person.id ?? "0-0-0-0-0",
            name: person.name,
            firstLastName: person.last_name,
            secondLastName: person.last_name_2,
            totalValue: person.total_value == 0 ? null : person.total_value,
            percent: person.porcent == 0 ? null : person.porcent,
            legalAgent: type.code === "PTRL",
            majorityShareholder: type.code === "PTAM",
            actualOwner: type.code === "PTPR",
            isNew: false,
          };
        });

      setShareHolders(personFiltered);
    }
  }, [getPersonsStatus, personTypesFilled]);

  return (
    <Box>
      <Typography fontSize={"25px"} color={"#002652"} fontWeight={600} mt={3} mb={3}>
        Cuadro accionario
      </Typography>
      <Button
        onClick={handleOnClickAddShareHolder}
        startIcon={<AddIcon />}
        sx={{ mb: 2, fontWeight: 400 }}
        disabled={applicationSelected.dictamenCompletado || editEnabled}
      >
        Agregar accionista
      </Button>
      <StripedDataGrid
        apiRef={apiRef}
        loading={!getPersonsStatus.isSuccess}
        autoHeight
        columns={
          applicationSelected.dictamenCompletado
            ? columnsDef.filter((item) => item.headerName !== "Acciones")
            : columnsDef
        }
        rows={shareHolders}
        hideFooter
        processRowUpdate={handleProcessRowUpdated}
        onRowEditStop={handleRowEditStop}
        onRowModesModelChange={handleRowModesModelChange}
        editMode="row"
      />
    </Box>
  );
};
