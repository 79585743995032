import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button, Grid2 as Grid, Typography } from "@mui/material";

import { RootState } from "@store/store";
import { formatCurrency, formatYears } from "@helpers/formats";
import { createRowTable, createSimpleRow } from "@helpers/components";
import { useGetCompanyProfileQuery } from "@services/api/applications";
import { CategoriasAnalisisGrafico } from "@components/CategoriasAnalisisGrafico";

import DownloadIcon from "@assets/download_icon.svg";

export const Perfil = () => {
  const [values, setValues] = useState<Array<number>>([]);

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const companyProfile = useGetCompanyProfileQuery(
    { rfc: applicationSelected.rfc, loanApplicationId: applicationSelected.id ?? "0-0-0-0-0" },
    {
      skip: applicationSelected.rfc === undefined,
    },
  );

  const handleOnDownloadReportClicked = () => {};

  useEffect(() => {
    if (companyProfile.isSuccess && companyProfile.currentData) {
      const tmp = Object.values(companyProfile.data.analysis_categories).map((t) => parseFloat(t));
      tmp.pop();
      setValues(tmp);
    }
  }, [companyProfile]);

  return (
    <Grid container>
      <Grid container direction={"column"}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Perfil Empresa
        </Typography>
        <Grid container>
          {createRowTable(
            "Ventas Anuales Facturadas",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details.annual_sales_invoiced ?? "0"),
              "$",
              0,
            ),
            "Compras Anuales Facturadas",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details.annual_expenses_invoiced ?? "0"),
              "$",
              0,
            ),
          )}
          {createRowTable(
            "Ingresos Anuales Facturados",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details.annual_revenues_invoiced ?? "0"),
              "$",
              0,
            ),
            "Egresos Anuales Facturados",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details.annual_expenses_invoiced ?? "0"),
              "$",
              0,
            ),
          )}
          {createRowTable(
            "Número de Empleados",
            companyProfile.currentData?.general_company_details.number_employees ?? "0",
            "Nómina Mensual",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details.monthly_payroll ?? "0"),
              "$",
              0,
            ),
          )}
          {createRowTable(
            "Score Pyme (Empresa)",
            formatCurrency(
              parseFloat(companyProfile.currentData?.general_company_details.score_company ?? "0"),
              "$",
              0,
            ),
            "BC Score (Persona)",
            formatCurrency(parseFloat(companyProfile.currentData?.general_company_details.bc_score ?? "0"), "$", 0),
          )}
          {createRowTable("Score Syntage", "", "Listas Negras", "")}
          {createRowTable(
            "Antigüedad",
            formatYears(parseFloat(companyProfile.currentData?.general_company_details.seniority ?? "0")),
            "Opinión de Cumplimiento",
            "",
          )}
          {createSimpleRow(
            "Actividad Preponderante (%)",
            companyProfile.currentData?.general_company_details.predominant_activity ?? "",
          )}
          {createSimpleRow("Uso del crédito", companyProfile.currentData?.credit_use ?? "")}
        </Grid>
      </Grid>
      <Grid container direction={"column"} width={"100%"}>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Categorías de Análisis
        </Typography>
        <Grid container direction={"row"}>
          <Grid container direction={"row"} size={"grow"} pr={4}>
            {createSimpleRow(
              "Ventas, Compras, Flujo",
              `${formatCurrency(
                Number(companyProfile.currentData?.analysis_categories.sales_purchases_flow) ?? 0,
                "",
              )}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Clientes, Proveedores, P&G",
              `${formatCurrency(
                Number(companyProfile.currentData?.analysis_categories.customers_suppliers_ps) ?? 0,
                "",
              )}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Nómina y empleados",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.payroll_employees) ?? 0, "")}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Financieros Declarados",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.financial_declared) ?? 0, "")}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Buró Acreditado",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.accredited_bureau) ?? 0, "")}%`,
              [4, 8],
            )}
            {createSimpleRow(
              "Buró Persona",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.bureau_person) ?? 0, "")} %`,
              [4, 8],
            )}
            {createSimpleRow(
              "CSF & Otros",
              `${formatCurrency(Number(companyProfile.currentData?.analysis_categories.csf_others) ?? 0, "")}%`,
              [4, 8],
            )}
            <Grid size={{ xs: 4 }} p={1}>
              <Typography variant="body1" color={"#002652D9"} fontWeight={700}>
                Total:
              </Typography>
            </Grid>
            <Grid size={{ xs: 8 }} p={1}>
              <Typography color={"#002652D9"} fontWeight={600}>
                {`${formatCurrency(Number(companyProfile.currentData?.analysis_categories.total) ?? 0, "")} %`}
              </Typography>
            </Grid>
            <Grid size={12} height={"50%"}></Grid>
          </Grid>
          <Grid size={6} mt={-15}>
            <CategoriasAnalisisGrafico data={values} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container width={"100%"} direction={"column"} alignContent={"end"} mt={4}>
        <Button
          endIcon={<img src={DownloadIcon} />}
          variant="text"
          sx={{ color: "#528CD6" }}
          onClick={handleOnDownloadReportClicked}
        >
          Descargar Reporte
        </Button>
      </Grid>
    </Grid>
  );
};
