import { useState } from "react";
import { Box, BoxProps, styled, Tab, Tabs, TabsProps } from "@mui/material";

interface CustomTabsProps extends TabsProps {
  titleTabs: Array<string>;
  alias?: string;
  onChangeTab?: (titleTab: string, index: number) => void;
}

interface TabPanelProps extends BoxProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  alias?: string;
}

const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  backgroundColor: "#528CD6",
  borderTopLeftRadius: "14px",
  borderTopRightRadius: "14px",

  "& .MuiButtonBase-root.MuiTab-root": {
    fontSize: "20px",
    fontWeight: 700,
    color: "#FFF",
    // borderRight: "2px solid #FFF",
    textTransform: "None",
    background:
      "linear-gradient(to top, #528CD6 0, #528CD6 25%, white 25%, white 75%, #528CD6 75%, #528CD6 ) right no-repeat",
    backgroundSize: "3px 100%",
  },
  "& .MuiButtonBase-root.MuiTab-root:has(+.Mui-selected)": {
    // borderRight: "0px",
    background: "",
    backgroundSize: "0px 100%",
  },
  "& .MuiButtonBase-root.MuiTab-root:last-of-type": {
    // borderRight: "0px",
    backgroundSize: "0px 100%",
  },
  "& .Mui-selected": {
    backgroundColor: "#002652 !important",
    fontWeight: 700,
    fontSize: "20px",
    color: "#FFF",
    textTransform: "None",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
    borderRight: "0px solid orange !important",
    backgroundSize: "0px 100% !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#002652",
  },
}));

export const CustomTabPanel = (props: TabPanelProps) => {
  const { children, index, value, alias, ...other } = props;

  return value === index ? (
    <Box
      role={"tabpanel"}
      id={`simple-tabpanel-${alias ? `${alias}-` : ""}${index}`}
      aria-labelledby={`simple-tab-${alias ? `${alias}-` : ""}${index}`}
      visibility={value !== index ? "hidden" : "visible"}
      position={value !== index ? "absolute" : "static"}
      maxHeight={value !== index ? 0 : props.height}
      maxWidth={value !== index ? 0 : props.width}
      {...other}
    >
      {children}
    </Box>
  ) : (
    <></>
  );
};

export const CustomTabs = ({ titleTabs, alias, onChangeTab }: CustomTabsProps) => {
  const [value, setValue] = useState<number>(0);

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${alias ? `${alias}-` : ""}${index}`,
      "aria-controls": `simple-tabpanel-${alias ? `${alias}-` : ""}${index}`,
    };
  };

  const handleOnChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    onChangeTab?.(titleTabs[newValue], newValue);
  };

  return (
    <StyledTabs value={value} variant="fullWidth" onChange={handleOnChangeTab} aria-label="secondary tabs example">
      {titleTabs.map((title: string, index: number) => (
        <Tab key={index} label={title} {...a11yProps(index)} />
      ))}
    </StyledTabs>
  );
};
