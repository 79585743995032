import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    default: true;
    orange: true;
    black: true;
    blue_outlined: true;
    blue_demon: true;
    indian_red: true;
    cardinal: true;
    zomp: true;
  }
}

export const BackOfficeTheme = createTheme({
  typography: {
    fontFamily: ["Outfit"].join(","),
    h1: {
      fontSize: 39,
      fontWeight: 300,
    },
    h2: {
      fontSize: 31,
      fontWeight: 300,
    },
    h3: {
      fontSize: 25,
      fontWeight: 300,
    },
    h4: {
      fontSize: 20,
      fontWeight: 300,
    },
    h5: {
      fontSize: 16,
      fontWeight: 300,
    },
    body1: {
      fontSize: 16,
      fontWeight: 300,
    },
    body2: {
      fontSize: 13,
      fontWeight: 300,
    },
    body3: {
      fontSize: 11,
      fontWeight: 300,
      fontFamily: "Outfit",
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#A3D4E8",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A3D4E8",
          },
          "& .MuiInput-underline::before": {
            borderColor: "#A3D4E8",
          },
          "& .MuiFilledInput-underline::before": {
            borderColor: "#A3D4E8",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#FF6060",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          // padding: "10px 25px",
          textTransform: "none",
          fontSize: 16,
          fontWeight: 700,
        },
      },
      variants: [
        {
          props: {
            variant: "default",
          },
          style: {
            backgroundColor: "#528CD6",
            color: "#FFF",
            "&.Mui-disabled": {
              backgroundColor: "#528CD6",
              opacity: 0.4,
            },
            "&:hover": {
              backgroundColor: "#528CD6",
            },
            "&.MuiButton-defaultSizeSmall": {
              fontSize: 11,
              fontWeight: 600,
              padding: "2px 8px",
            },
          },
        },
        {
          props: {
            variant: "orange",
          },
          style: {
            backgroundColor: "#F2704F",
            color: "#FFF",
            "&.Mui-disabled": {
              backgroundColor: "#F2704F",
              opacity: 0.4,
            },
            "&:hover": {
              backgroundColor: "#F2704F",
            },
          },
        },
        {
          props: {
            variant: "black",
          },
          style: {
            backgroundColor: "#2C2C2C",
            color: "#FFF",
            "&.Mui-disabled": {
              backgroundColor: "#2C2C2C",
              opacity: 0.4,
            },
            "&:hover": {
              backgroundColor: "#2C2C2C",
            },
          },
        },
        {
          props: {
            variant: "blue_outlined",
          },
          style: {
            color: "#002652",
            backgroundColor: "#FFF",
            border: "2px solid #002652",
            lineHeight: "22px",
            "&.Mui-disabled": {
              color: "#bdbdbd",
              border: "2px solid #bdbdbd",
              backgroundColor: "transparent",
            },
            "&.MuiButton-blue_outlinedSizeSmall": {
              fontSize: 11,
              fontWeight: 600,
              padding: "2px 8px",
            },
          },
        },
        {
          props: {
            variant: "blue_demon",
          },
          style: {
            // color: "#A3D4E8",
            color: "#FFFFFF",
            backgroundColor: "#002652",
            "&.Mui-disabled": {
              backgroundColor: "#002652",
              opacity: 0.4,
            },
            "&:hover": {
              backgroundColor: "#002652",
            },
          },
        },
        {
          props: {
            variant: "indian_red",
          },
          style: {
            color: "#FFFFFF",
            backgroundColor: "#E35050",
            "&.Mui-disabled": {
              backgroundColor: "#E35050",
              opacity: 0.4,
              color: "#FFF",
            },
            "&:hover": {
              backgroundColor: "#ab1b1e",
            },
          },
        },
        {
          props: {
            variant: "cardinal",
          },
          style: {
            color: "#FFFFFF",
            backgroundColor: "#BB3545",
            "&.Mui-disabled": {
              backgroundColor: "#BB3545",
              opacity: 0.4,
              color: "#FFF",
            },
            "&:hover": {
              backgroundColor: "#ab2e3a",
            },
          },
        },
        {
          props: {
            variant: "zomp",
          },
          style: {
            color: "#FFFFFF",
            backgroundColor: "#01A388",
            "&.Mui-disabled": {
              backgroundColor: "#01A388",
              opacity: 0.4,
              color: "#FFF",
            },
            "&:hover": {
              backgroundColor: "#00664c",
            },
          },
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#FF6060",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-asterisk": {
            color: "#FF6060",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // "& .MuiSelect-icon": {
          //   color: "#A3D4E8",
          // },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A3D4E8",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          ":disabled": {
            cursor: "not-allowed",
            opacity: 0.4,
            pointerEvents: "auto",
          },
        },
      },
    },
  },
});
