import { setBpmUserLogged, setUserPermissions } from "@store/slices/appSlice";
import { baseApi } from "./baseApi";
import { BpmUser, UserResponse } from "@interfaces/users";

const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUserInfoV2: build.query<UserResponse, string>({
      query: (sso_id) => ({
        url: `usuarios/${sso_id}`,
      }),
      transformResponse: (response: UserResponse) => {
        return response;
      },
      async onQueryStarted(arg, api) {
        const { data } = await api.queryFulfilled;
        api.dispatch(setBpmUserLogged(data.data.user_data));
        api.dispatch(setUserPermissions(data.data.permissions));
      },
    }),
  }),
});

export const { useLazyGetCurrentUserInfoV2Query } = usersApi;
