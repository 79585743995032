import { Container, Grid2 as Grid, Typography } from "@mui/material";

import DownloadOkIcon from "@assets/download_ok_icon.svg";
import DownloadWaitIcon from "@assets/download_wait_icon.svg";
import DownloadWarningIcon from "@assets/download_warning_icon.svg";
import CheckIcon from "@mui/icons-material/Check";

export const EstatusDescargasSat = () => {
  const createSectionStatus = (title: string, status: "Descargado" | "Descargando" | "Faltantes" | "No Hit") => {
    return (
      <Grid>
        <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          {status === "Descargado" && <CheckIcon sx={{ color: "#528CD6", width: 13, height: 11 }} />}
          {status === "Descargando" && <img src={DownloadWaitIcon} />}
          {(status === "Faltantes" || status === "No Hit") && <img src={DownloadWarningIcon} />}
          <Typography variant="body3" fontWeight={500} color="#002652" mt={0.2}>
            {title}
          </Typography>
          <Typography
            variant="body3"
            fontWeight={500}
            color={
              status === "Faltantes" || status === "No Hit"
                ? "#F2704F"
                : status === "Descargando"
                ? "#01A388"
                : "#528CD6"
            }
          >
            {status}
          </Typography>
        </Container>
      </Grid>
    );
  };
  return (
    <Grid
      py={2}
      rowSpacing={2}
      container
      sx={{ backgroundColor: "#E3F2F8", borderRadius: "8px" }}
      justifyContent={"space-between"}
      maxWidth={"90%"}
    >
      {createSectionStatus("Facturas", "Descargado")}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus("Declaración", "Descargado")}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus("Buró Empresa", "No Hit")}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus("Buró Persona", "Faltantes")}
      <Grid border={"1px solid #A3D4E8"}></Grid>
      {createSectionStatus("Info Laboral", "Descargando")}
    </Grid>
  );
};
