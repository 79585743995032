import { Grid2 as Grid, Stack, Typography } from "@mui/material";

import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CategoryScale, ChartData, ChartOptions } from "chart.js";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

interface GraficoDonaGenericoProps {
  title: string;
  data: Array<number>;
  labels: Array<string>;
  colors: Array<string>;
}

export const GraficoDonaGenerico = (props: GraficoDonaGenericoProps) => {
  const data: ChartData<"doughnut"> = {
    labels: props.data,
    datasets: [{ label: "", data: props.data, backgroundColor: props.colors }],
  };

  const options: ChartOptions<"doughnut"> = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        color: "#fff",
      },
    },
  };

  return (
    <Grid container ml={1}>
      <Grid size={12}>
        <Typography variant="h4" fontWeight={600} color={"#002652"}>
          {props.title}
        </Typography>
      </Grid>
      <Grid size={6}>
        <Stack height={"100%"} pl={2} sx={{ justifyContent: "space-around" }}>
          {props.labels.map((label, idx) => {
            return (
              <Typography key={idx}>
                <Typography component={"span"} mr={1} sx={{ position: "relative", top: "-2px" }}>
                  ■
                </Typography>
                {label}
              </Typography>
            );
          })}
        </Stack>
      </Grid>
      <Grid size={6}>
        <Doughnut data={data} options={options} />
      </Grid>
    </Grid>
  );
};
