import { useEffect, useState } from "react";

import { RootState, store } from "@store/store";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Person } from "@interfaces/persons";
import { DocumentInfo } from "@interfaces/documents";
import { FileSectionV2 } from "@components/FileSectionV2";
import {
  DialogType,
  DocumentAction,
  setApplicationStateCode,
  setCompanyDocsValidated,
  setDialogConfig,
  setDocumentAction,
  setExistSDC,
} from "@store/slices/appSlice";
import { generateDummyDocuments, getMimeTypeFromFilename } from "@helpers/generals";
import { useGetFileCategoriesQuery, useLazyGetStatusQuery } from "@services/api/catalogs";
import { usePutApplicationsMutation, usePostStatusApplicationMutation } from "@services/api/applications";
import {
  useGetUrlToDownloadDocMutation,
  useLazyGetDocumentsByAppQuery,
  useLazyGetSignUrlToUploadDocQuery,
  usePatchDocumentMutation,
  usePostDocumentMutation,
  usePostUploadFileMutation,
} from "@services/api/documents";

export const DocumentosDictamen = () => {
  const dispatch = useDispatch();

  const [checkForChange, setCheckForChange] = useState<boolean>(false);
  const [reprLegal, setReprLegal] = useState<Person>();

  const documentAction = (state = store.getState()) => state.app.components.documentAction;
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const [triggerGetDocuments, resultGetDocuments] = useLazyGetDocumentsByAppQuery({
    selectFromResult: ({ data }) => ({
      documents: data?.data?.company,
    }),
  });

  const documenCategories = useGetFileCategoriesQuery();

  const [triggerGetStatus] = useLazyGetStatusQuery();
  const [triggerPostDocument] = usePostDocumentMutation();
  const [triggerPatchDocument] = usePatchDocumentMutation();
  const [triggerPostUploadDoc] = usePostUploadFileMutation();
  const [triggerPutApplication] = usePutApplicationsMutation();
  const [triggerPatchApplication] = usePutApplicationsMutation();
  const [triggerPostUrlDocument] = useGetUrlToDownloadDocMutation();
  const [triggerPostUrlToUploadDocr] = useLazyGetSignUrlToUploadDocQuery();
  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const handleOnFileActionClicked = async (
    action: DocumentAction,
    file: DocumentInfo,
    newFile?: EventTarget & HTMLInputElement,
  ) => {
    if (action === DocumentAction.SEE) {
      const urlDownload = await triggerPostUrlDocument(file.url);

      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob], { type: getMimeTypeFromFilename(file.file_name) }));
      window.open(url);
    } else if (action === DocumentAction.DOWNLOAD) {
      const urlDownload = await triggerPostUrlDocument(file.url);
      const response = await fetch(urlDownload.data ?? "");
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      link.setAttribute("download", file.file_name);

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } else if (action === DocumentAction.UPLOAD) {
      const tmpFile = newFile?.files?.[0];
      try {
        if (file.id === undefined) {
          file.client_details_id = JSON.parse(applicationSelected.fullData)["client_details_id"];
        }

        const urlToUploadResponse = await triggerPostUrlToUploadDocr({
          upload_type: "client",
          file_name: tmpFile?.name ?? "",
          regimen: "pm",
          person_id: file.person_id,
          client_details_id: file.client_details_id,
          file_category_id: file.file_category_id,
          loan_application_id: applicationSelected.id,
        }).unwrap();

        const signUrl = urlToUploadResponse.data;

        const tmp = new FormData();

        tmp.append("key", signUrl.fields.key);
        tmp.append("AWSAccessKeyId", signUrl.fields.AWSAccessKeyId);
        tmp.append("x-amz-security-token", signUrl.fields["x-amz-security-token"]);
        tmp.append("policy", signUrl.fields.policy);
        tmp.append("signature", signUrl.fields.signature);
        tmp.append("file", tmpFile);

        await triggerPostUploadDoc({
          url: signUrl.url,
          body: tmp,
        }).unwrap();

        if (file.id !== undefined) {
          await triggerPatchDocument({
            applicationId: applicationSelected.id,
            documentId: file.id,
            body: {
              status: false,
            },
          }).unwrap();
        }

        await triggerPostDocument({
          loan_application_id: applicationSelected.id ?? "0-0-0-0-0",
          file_category_id: file.file_category_id ?? "0-0-0-0-0",
          url: signUrl.fields.key,
          file_name: tmpFile?.name ?? "",
          client_details_id: file.category_code === "VIDME" ? undefined : file.client_details_id,
          person_id: file.category_code === "VIDME" ? file.person_id : undefined,
        }).unwrap();
      } catch (error) {
      } finally {
        tmpFile.value = "";
        await triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" });
      }
    } else if (action === DocumentAction.VALIDATE) {
      dispatch(
        setDocumentAction({
          action,
          doc: file,
        }),
      );
      dispatch(
        setDialogConfig({
          shown: true,
          type: DialogType.VALIDAR_DOC,
          props: {
            title: `Estas seguro de validar el documento ${file.category_name} de:`,
          },
          onAcceptClick: async () => {
            await handleOnModalConfirmClicked();
          },
        }),
      );
    } else if (action === DocumentAction.REJECT) {
      dispatch(
        setDocumentAction({
          action,
          doc: file,
        }),
      );
      dispatch(
        setDialogConfig({
          shown: true,
          type: DialogType.RECHAZAR_DOC,
          props: {
            document: file,
          },
          onAcceptClick: handleOnModalConfirmClicked,
        }),
      );
    }
  };

  const handleOnModalConfirmClicked = async () => {
    const docAction = documentAction();

    if (docAction.action === DocumentAction.REJECT) {
      const statusCatalog = await triggerGetStatus();
      const currentStatusId = JSON.parse(applicationSelected.fullData)["status_id"];
      const currentStatusCode = statusCatalog?.data?.data.data.find((item) => item.id === currentStatusId)?.code;

      setCheckForChange(true);

      if (currentStatusCode === "CSEDC") {
        await triggerPostStatusApplication({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          statusCode: "CSECI",
          disableSequenceCheck: true,
        }).unwrap();
      }

      if (docAction.doc?.category_code === "SDC") {
        await triggerPatchApplication({
          applicationId: applicationSelected.id,
          body: {
            completed: false,
            loan_application_completed: false,
            references_completed: false,
            bank_information_completed: false,
          },
        });
      }

      if (["DIORL", "DAC", "DAA"].find((doc) => doc === docAction.doc?.category_code)) {
        await triggerPatchApplication({
          applicationId: applicationSelected.id,
          body: {
            decision_validated: false,
          },
        });
      }

      const loan_application_completed = ["DCD"].find((doc) => doc === docAction.doc?.category_code) ? false : null;
      const bank_information_completed = ["DECB"].find((doc) => doc === docAction.doc?.category_code) ? false : null;

      if (loan_application_completed === false || bank_information_completed === false) {
        await triggerPatchApplication({
          applicationId: applicationSelected.id,
          body: {
            completed: false,
            loan_application_completed,
            bank_information_completed,
          },
        });
      }

      await triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" }).unwrap();
    } else if (docAction.action === DocumentAction.VALIDATE) {
      setCheckForChange(true);
      try {
        await triggerPatchDocument({
          applicationId: applicationSelected.id,
          documentId: docAction.doc?.id ?? "0-0-0-0-0",
          body: {
            validated: true,
          },
        }).unwrap();

        if (docAction.doc?.category_code === "CDVOC") {
          await triggerPostStatusApplication({
            applicationId: applicationSelected.id ?? "0-0-0-0-0",
            statusCode: "CSEVD",
            disableSequenceCheck: true,
          }).unwrap();

          dispatch(setApplicationStateCode("CSEVD"));
        }

        await triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" }).unwrap();
      } catch (error) {
        console.log("Error al validar documento: %s", error);
      }
    }
    dispatch(
      setDialogConfig({
        shown: false,
        type: DialogType.NONE,
        props: undefined,
      }),
    );
  };

  useEffect(() => {
    const docsToValidated = [];
    const checkValidateFolder = [];
    if (applicationSelected.tax_systems.code === "CS002") {
      //PFAE
      docsToValidated.push("DCD", "SDC"); //Se remueve la constancia de situación fiscal -> DCDF
      checkValidateFolder.push("DCD", "SDC"); //Se remueve la constancia de situación fiscal -> DCDF
    } else {
      docsToValidated.push("DCD", "SDC", "DIORL", "DAC", "CDDTN"); //Se remueve la constancia de situación fiscal -> DCDF
      checkValidateFolder.push("DCD", "SDC"); //Se remueve la constancia de situación fiscal -> DCDF
    }

    const isCompletedFolder =
      checkValidateFolder
        .map((code) => resultGetDocuments.documents?.grals.some((doc) => doc.category_code == code && doc.validated))
        .every((flag) => flag === true) ?? false;

    const result =
      docsToValidated
        .map((code) => resultGetDocuments.documents?.grals.some((doc) => doc.category_code == code && doc.validated))
        .every((flag) => flag === true) ?? false;

    const docs_accounts_validated =
      resultGetDocuments.documents?.accounts
        .map((account) => account.bank_statements.every((statement) => statement.validated))
        .every((t) => t === true) ?? false;

    const docs_accounts_completed =
      resultGetDocuments.documents?.accounts
        .map((account) => account.bank_statements.length === 3)
        .every((t) => t === true) ?? false;

    if (checkForChange && isCompletedFolder && docs_accounts_completed && docs_accounts_validated) {
      try {
        triggerPutApplication({
          applicationId: applicationSelected.id,
          body: {
            completed: true,
          },
        }).unwrap();
      } catch (error) {
        console.log(error);
        console.error("No se pudo cambiar el estado de la empresa a completed");
      } finally {
        setCheckForChange(false);
      }
    }

    const existsSDC = resultGetDocuments?.documents?.grals.some((item) => item.category_code === "SDC") ?? false;
    dispatch(setExistSDC(existsSDC));

    dispatch(setCompanyDocsValidated(result && docs_accounts_validated && docs_accounts_completed));
  }, [resultGetDocuments]);

  useEffect(() => {
    // const tabs = ["MGEN", "MEXE", "MDIC", "MVOC", "MCON", "MRCR"];

    // setCurrentVerticalTab(
    //   tabs.findIndex(
    //     (item) => item === sessionPermissions(store.getState()).find((permission) => permission.can_watch)?.module.code,
    //   ),
    // );

    triggerGetDocuments({ applicationId: applicationSelected.id, type: "company" });

    triggerGetStatus().then((values) => {
      const currentStatusId = JSON.parse(applicationSelected.fullData)["status_id"];
      const currentStatusCode = values.data?.data?.data?.find((item) => item.id === currentStatusId)?.code;

      const bFlag = ["CSEDV", "CSAVC", "CSVYC", "CSIYA", "CSREC", "CSRDO", "CSFIR", "CSEVD"].find(
        (item) => item === currentStatusCode,
      );

      //   setCompanyTabs([
      //     {
      //       title: "Perfil",
      //       active: true,
      //       hidden:
      //         sessionPermissions(store.getState()).find(
      //           (permission) => permission.module.code === "MGEN" && permission.can_watch,
      //         ) === undefined,
      //     },
      //     {
      //       title: "Expediente",
      //       active: true,
      //       hidden:
      //         sessionPermissions(store.getState()).find(
      //           (permission) => permission.module.code === "MEXE" && permission.can_watch,
      //         ) === undefined,
      //     },
      //     {
      //       title: "Dictamen",
      //       active: true,
      //       hidden:
      //         sessionPermissions(store.getState()).find(
      //           (permission) => permission.module.code === "MDIC" && permission.can_watch,
      //         ) === undefined || applicationSelected.tax_systems.code === "CS002",
      //     },
      //     {
      //       title: "Visita Ocular",
      //       active: bFlag,
      //       hidden:
      //         sessionPermissions(store.getState()).find(
      //           (permission) => permission.module.code === "MVOC" && permission.can_watch,
      //         ) === undefined,
      //     },
      //     {
      //       title: "Contractuales",
      //       active: bFlag,
      //       hidden:
      //         sessionPermissions(store.getState()).find(
      //           (permission) => permission.module.code === "MCON" && permission.can_watch,
      //         ) === undefined,
      //     },
      //     {
      //       title: "Resumen crédito",
      //       active: bFlag,
      //       hidden:
      //         sessionPermissions(store.getState()).find(
      //           (permission) => permission.module.code === "MRCR" && permission.can_watch,
      //         ) === undefined,
      //     },
      //   ]);

      const repLegal = JSON.parse(applicationSelected.fullData)["person"].find(
        (person: Person) => person.person_type?.code === "PTRL",
      );
      setReprLegal(repLegal);
    });
  }, []);

  return (
    <Box margin={3}>
      <FileSectionV2
        showValidate
        showReject
        title="Identificación representante legal"
        documents={
          resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DIORL")
            ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DIORL")
            : generateDummyDocuments(
                1,
                documenCategories.currentData?.find((category) => category.code === "DIORL"),
              )
        }
        showHeaders
        onFileActionClick={handleOnFileActionClicked}
      />
      <FileSectionV2
        showValidate
        showReject
        title="Acta constitutiva"
        documents={
          resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DAC")
            ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DAC")
            : generateDummyDocuments(
                1,
                documenCategories.currentData?.find((category) => category.code === "DAC"),
              )
        }
        onFileActionClick={handleOnFileActionClicked}
      />
      <FileSectionV2
        showValidate
        showReject
        title="Actas adicionales"
        documents={
          resultGetDocuments?.documents?.grals.some((item) => item.category_code === "DAA")
            ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "DAA")
            : generateDummyDocuments(
                1,
                documenCategories.currentData?.find((category) => category.code === "DAA"),
              )
        }
        onFileActionClick={handleOnFileActionClicked}
      />
      <Typography variant="h4" fontWeight={600} color={"#002652"} pt={4}>
        Dictamen
      </Typography>
      <FileSectionV2
        showValidate
        showReject
        title=""
        documents={
          resultGetDocuments?.documents?.grals.some((item) => item.category_code === "CDDTN")
            ? resultGetDocuments?.documents?.grals.filter((item) => item.category_code === "CDDTN")
            : generateDummyDocuments(
                1,
                documenCategories.currentData?.find((category) => category.code === "CDDTN"),
              )
        }
        onFileActionClick={handleOnFileActionClicked}
        showButtonGo
        showHeaders
      />
    </Box>
  );
};
