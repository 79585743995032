import { useState } from "react";

import { CustomTabs, CustomTabPanel } from "@components/CustomTabs";
import { Agendar } from "@components/SolicitudSegmentos/Visita/Agendar";
import { VisitaDoc } from "@components/SolicitudSegmentos/Visita/VisitaDoc";

export const VisitaOcular = () => {
  // const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleOnChangeTab = async (_: string, indexTab: number) => {
    setCurrentTab(indexTab);
  };

  return (
    <>
      <CustomTabs titleTabs={["Agendar Visita", "Visita"]} onChangeTab={handleOnChangeTab}></CustomTabs>
      <CustomTabPanel index={0} value={currentTab}>
        <Agendar />
      </CustomTabPanel>
      <CustomTabPanel index={1} value={currentTab}>
        <VisitaDoc />
      </CustomTabPanel>
    </>
  );
};
