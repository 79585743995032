import { formatCurrency } from "@helpers/formats";
import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";
import { useGetCompanyProfileQuery } from "@services/api/applications";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";

import DownloadIcon from "@assets/download_icon.svg";
import { createRowTable } from "@helpers/components";
import { EstatusDescargasSat } from "@components/EstatusDescargasSat";

export const General = () => {
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const companyProfile = useGetCompanyProfileQuery(
    { rfc: applicationSelected.rfc, loanApplicationId: applicationSelected.id ?? "0-0-0-0-0" },
    {
      skip: applicationSelected.rfc === undefined,
    },
  );

  const handleOnDownloadReportClicked = () => {};

  return (
    <Grid container>
      <Grid container direction={"column"}>
        <Grid container>
          <Grid size={6}>
            <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
              Descargas
            </Typography>
            <EstatusDescargasSat />
          </Grid>
          <Grid size={6}>
            <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
              Etapa
            </Typography>
            <Box px={5}>
              <Grid container textAlign={"center"} borderBottom={"1px solid rgba(163, 212, 232, 0.5)"}>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#0026528C">
                    Etapa
                  </Typography>
                </Grid>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#0026528C">
                    Subetapa
                  </Typography>
                </Grid>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#0026528C">
                    Estatus/Pantalla
                  </Typography>
                </Grid>
              </Grid>
              <Grid container textAlign={"center"}>
                <Grid size={4}>
                  <Typography variant="body2" fontWeight={700} color="#528CD6" pt={1}>
                    {applicationSelected.etapa}
                  </Typography>
                </Grid>
                <Grid
                  size={4}
                  borderLeft={"1px solid rgba(163, 212, 232, 0.5)"}
                  borderRight={"1px solid rgba(163, 212, 232, 0.5)"}
                >
                  <Typography variant="body2" pt={1}>
                    {applicationSelected.subEtapa}
                  </Typography>
                </Grid>
                <Grid size={4}>
                  <Typography variant="body2" pt={1}>
                    {applicationSelected.estatus}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Modelo
        </Typography>
        <Grid container>
          {createRowTable("ID Solicitud", applicationSelected.solicitudId.toString(), "ID Modelo", "")}
          {createRowTable(
            "Resultado:",
            companyProfile.currentData?.model_data.score_code ?? "",
            "RFC",
            applicationSelected.rfc,
          )}
          {createRowTable(
            "Calificación:",
            formatCurrency(Number(companyProfile.currentData?.model_data.score) ?? 0, "", 1),
            "Score",
            "",
          )}
          {createRowTable(
            "Monto Seleccionado:",
            formatCurrency(applicationSelected.montoSolicitadoOriginal ?? 0, "$", 0),
            "Fecha Inicio Solicitud",
            "",
          )}
          {createRowTable(
            "Monto Máximo Autorizado:",
            formatCurrency(parseFloat(companyProfile.currentData?.model_data.authorized_amount ?? "0"), "$", 0),
            "Fecha Modelo",
            "",
          )}
          {createRowTable(
            "Plazo seleccionado",
            `${applicationSelected.plazoSolicitadoOriginal ?? 0} meses`,
            "Días de Validez Modelo",
            "",
          )}
          {createRowTable(
            "Plazo Máximo Autorizado",
            `${companyProfile.currentData?.model_data.authorized_term ?? 0} meses`,
            "Tasa",
            `${formatCurrency(companyProfile.currentData?.model_data.interest_rate ?? 0, "", 1)}%`,
          )}
        </Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Usuario y Accionista
        </Typography>
        <Grid container>
          {createRowTable(
            "Usuario Solicitud",
            applicationSelected.usuario.nombre,
            "Correo Usuario",
            applicationSelected.usuario.correo,
          )}
          {createRowTable(
            "Accionista Mayoritario",
            applicationSelected.accionistaMayoritario.nombre,
            "RFC Accionista Mayoritario",
            applicationSelected.accionistaMayoritario.rfc,
          )}
        </Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Generales
        </Typography>
        <Grid container>
          {createRowTable(
            "Nombre Comercial",
            companyProfile.currentData?.general_data.company_name ?? "",
            "Pagina Web",
            companyProfile.currentData?.general_data.web_page ?? "",
          )}
          {createRowTable(
            "Linkedin",
            companyProfile.currentData?.general_data?.linkedin ?? "",
            "Facebook",
            companyProfile.currentData?.general_data?.facebook ?? "",
          )}
          {createRowTable(
            "Twitter / X",
            companyProfile.currentData?.general_data?.x_twitter ?? "",
            "Instagram",
            companyProfile.currentData?.general_data?.instagram ?? "",
          )}
        </Grid>
        <Typography variant="h4" fontWeight={600} color={"#002652"} mt={4} mb={1}>
          Origen
        </Typography>
        <Grid container>
          {createRowTable("ID Canal", "", "Canal", applicationSelected.canal)}
          {createRowTable("Macrocanal", applicationSelected.macroCanal, "Detalle Canal", "")}
        </Grid>
      </Grid>
      <Grid container width={"100%"} direction={"column"} alignContent={"end"} mt={4}>
        <Button
          endIcon={<img src={DownloadIcon} />}
          variant="text"
          sx={{ color: "#528CD6" }}
          onClick={handleOnDownloadReportClicked}
        >
          Descargar Reporte
        </Button>
      </Grid>
    </Grid>
  );
};
