import { combineReducers, configureStore, Dispatch, UnknownAction } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
// import sessionStorage from "redux-persist/es/storage/session";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { thunk, ThunkDispatch } from "redux-thunk";

import appReducer from "./slices/appSlice";

import { baseApi } from "@services/api/baseApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useDispatch } from "react-redux";

const persistConfig = {
  key: "lenditgroup",
  storage: storage,
  whitelist: ["app"],
  blacklist: [baseApi.reducerPath],
};

const appsReducer = combineReducers({
  app: appReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const rootReducer = (state: any, action: UnknownAction) => {
  if (action.type === "USER_LOGOUT" || action.type === "CLEAN_STORE") {
    storage.removeItem("persist:root");
    return appsReducer(undefined, action);
  }
  return appsReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, "app/components", "app/setDialogConfig"],
        ignoredActionPaths: ["app.components", "meta.arg", "meta.baseQueryMeta"],
        ignoredPaths: ["app.components"],
      },
    })
      .concat(thunk)
      .concat(baseApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = ThunkDispatch<any, undefined, UnknownAction> & Dispatch<any>;
export const useAppDispatch: () => AppDispatch = useDispatch;
