import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useFormik } from "formik";

import { EMAIL, PASSWORD } from "@helpers/regex";

import Logo from "@assets/lendia_white.svg";
import RightImg from "@assets/right_1.svg";
import LendiaIcon from "@assets/lendia_icon.svg";
import { CustomSwitch } from "@components/CustomSwitch";
import {
  confirmResetPassword,
  fetchAuthSession,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
} from "aws-amplify/auth";

import { RootState } from "@store/store";
import { useLazyGetCurrentUserInfoV2Query } from "@services/api/users";
import { CustomModal } from "@components/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { setRecoveryUsername } from "@store/slices/appSlice";

export const RecoveryAccount = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const accountRecover = useSelector((state: RootState) => state.app.session.recovery.username);

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      code: "",
      newPassword: "",
    },
    validationSchema: yup.object().shape({
      newPassword: yup
        .string()
        .required("Campo requerido")
        .matches(
          PASSWORD,
          "Formato incorrecto. Debe contener una letra mayúscula, número, carácter especial y más de 8 caracteres.",
        ),
      code: yup.string().required("Campo requerido"),
    }),
    onSubmit: async (values) => {
      if (formik.isValid) {
        try {
          setLoading(true);
          setError(false);

          await confirmResetPassword({
            username: accountRecover,
            confirmationCode: values.code,
            newPassword: values.newPassword,
          });
          setShowModal(true);
        } catch (error) {
          setError(true);
          console.log(error);
          setLoading(false);
        }
      }
    },
  });

  return (
    <>
      <Container sx={{ height: "100vh", minWidth: "100%", p: "0 !important" }}>
        <img src={Logo} style={{ position: "absolute", marginTop: "10px", marginLeft: "10px" }}></img>
        <Grid container height={"100%"} alignItems={"center"}>
          <Grid
            container
            item
            xs={isMobileOrTablet ? 12 : 7}
            height={"100%"}
            alignItems={"center"}
            px={isMobileOrTablet ? 4 : 6}
            sx={{ backgroundColor: "#E3F2F8" }}
          >
            <Container
              sx={{
                width: "70%",
                minWidth: "420px",
                height: "50%",
                minHeight: "600px",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                boxShadow: "8px 4px 20px 0px #58585840",
              }}
            >
              <form onSubmit={formik.handleSubmit} noValidate>
                <Stack pt={5} direction={"column"} justifyContent={"flex-start"} alignItems={"center"}>
                  <Typography variant="h2" fontWeight={700} color="#002652">
                    ¡Hola de nuevo!
                  </Typography>
                  <Typography my={3} textAlign={"center"} variant="body1" color="#002652">
                    Restablecer contraseña de {accountRecover}
                  </Typography>
                  <TextField
                    sx={{ my: 2, width: "60%" }}
                    id="newPassword"
                    label="Nueva contraseña"
                    type="password"
                    required
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                  />

                  <TextField
                    sx={{ my: 2, width: "60%" }}
                    id="code"
                    label="Código de verificación"
                    type="password"
                    required
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                    helperText={formik.touched.newPassword && formik.errors.newPassword}
                  />
                  {error && (
                    <Box sx={{ mt: 2 }}>
                      <Alert variant="outlined" severity="error">
                        Codigo incorrecto. Verifica el código de confirmación.
                      </Alert>
                    </Box>
                  )}
                  <Button type="submit" variant="contained" fullWidth sx={{ py: 1, mb: 5, mt: 3, maxWidth: "60%" }}>
                    Resetear contraseña
                  </Button>
                </Stack>
              </form>
            </Container>
          </Grid>
          {!isMobileOrTablet && (
            <Grid item xs={12} md={5} height={"100%"} textAlign={"center"} sx={{ backgroundColor: "#002652" }}>
              <img src={RightImg} alt="" style={{ height: "calc(100vh - 160px)" }} />
            </Grid>
          )}
        </Grid>
        <img
          src={LendiaIcon}
          style={{ position: "absolute", left: 0, bottom: 0, marginLeft: "20px", marginBottom: "20px" }}
        ></img>
      </Container>
      <CustomModal open={showModal}>
        <Container sx={{ my: 2, alignItems: "center", textAlign: "center" }}>
          <Typography>Contraseña restablecida con éxito.</Typography>
          <Button
            variant="default"
            sx={{ mt: 2, py: 0.5 }}
            onClick={() => {
              navigate("/login");
            }}
          >
            Aceptar
          </Button>
        </Container>
      </CustomModal>
    </>
  );
};
