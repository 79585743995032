import { useEffect, useState } from "react";

import { CustomTabPanel, CustomTabs } from "@components/CustomTabs";
import { Empresa } from "@components/SolicitudSegmentos/Expediente/Empresa";
import { AvalPropietarios } from "@components/SolicitudSegmentos/Expediente/AvalPropietarios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { DialogType, setApplicationStateCode, setDialogConfig } from "@store/slices/appSlice";
import { useLazyGetDocumentsQuery, usePatchDocumentMutation } from "@services/api/documents";
import { usePostStatusApplicationMutation } from "@services/api/applications";

export const Expediente = () => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const [triggerGetDocuments] = useLazyGetDocumentsQuery();
  const [trigetPatchDocument] = usePatchDocumentMutation();
  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const handleOnChangeTab = async (_: string, indexTab: number) => {
    setCurrentTab(indexTab);
  };

  const handleModalValidateClicked = async () => {
    await triggerPostStatusApplication({
      applicationId: applicationSelected.id ?? "0-0-0-0-0",
      statusCode: "CSEDV",
      disableSequenceCheck: true,
    }).unwrap();

    const docs = await triggerGetDocuments({
      loan_application_id: applicationSelected.id,
    });

    const docsNotValidated = docs.data
      ?.filter((doc) => doc.status && doc.validated === false)
      .map((doc) => {
        return trigetPatchDocument({
          applicationId: applicationSelected.id,
          documentId: doc.id ?? "0-0-0-0-0",
          body: {
            validated: true,
          },
        });
      });

    if (docsNotValidated !== undefined) {
      Promise.all(docsNotValidated);
    }

    dispatch(
      setDialogConfig({
        shown: false,
        type: DialogType.NONE,
        props: undefined,
      }),
    );
    dispatch(setApplicationStateCode("CSEDV"));
  };

  useEffect(() => {
    if (
      applicationSelected.company_docs_validated &&
      applicationSelected.shareholder_docs_validated &&
      applicationSelected.owners_docs_validated
    ) {
      dispatch(
        setDialogConfig({
          shown: true,
          type: DialogType.VALIDAR_EXP,
          props: {
            title: "Estas seguro de validar el expediente de:",
          },
          onAcceptClick: handleModalValidateClicked,
        }),
      );
    }
  }, [
    applicationSelected.company_docs_validated,
    applicationSelected.shareholder_docs_validated,
    applicationSelected.owners_docs_validated,
  ]);

  return (
    <>
      <CustomTabs
        titleTabs={["Expediente Empresa", "Expediente Aval y Propietarios"]}
        onChangeTab={handleOnChangeTab}
      ></CustomTabs>
      <CustomTabPanel index={0} value={currentTab}>
        <Empresa />
      </CustomTabPanel>
      <CustomTabPanel index={1} value={currentTab}>
        <AvalPropietarios />
      </CustomTabPanel>
    </>
  );
};
