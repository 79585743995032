import { UUID } from "crypto";
import { baseApi } from "./baseApi";
import { SendToSignPayload } from "@interfaces/signatures";

const personsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSignatories: build.query<any, UUID | string>({
      query: (loanApplicationId) => ({
        url: `firmantes/${loanApplicationId}`
      }),
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
    sendToSign: build.mutation<any, SendToSignPayload>({
      query: (data) => ({
        url: "enviar_firmar",
        method: "POST",
        body: data,
      }),
      transformResponse: (response: any) => {
        return response.data;
      },
    }),
  }),
});

export const {
    useLazyGetSignatoriesQuery,
    useSendToSignMutation,
} = personsApi;
