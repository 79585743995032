import { OptionApplicationMenu } from "@store/slices/appSlice";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";
import { PerFilEmpresa } from "./secciones/PerfilEmpresa";
import { Dictamen } from "./secciones/Dictamen";
import { Expediente } from "./secciones/Expediente";
import { VisitaOcular } from "./secciones/VisitaOcular";
import { Contractuales } from "./secciones/Contractuales";
import { ResumenCliente } from "./secciones/ResumenCliente";

export const Solicitud = () => {
  const optionApplicationSelected = useSelector(
    (state: RootState) => state.app.components.menuApplication.optionSelected,
  );

  switch (optionApplicationSelected) {
    case OptionApplicationMenu.PERFIL:
      return <PerFilEmpresa />;
    case OptionApplicationMenu.DICTAMEN:
      return <Dictamen />;
    case OptionApplicationMenu.EXPEDIENTE:
      return <Expediente />;
    case OptionApplicationMenu.VISITA_OCULAR:
      return <VisitaOcular />;
    case OptionApplicationMenu.CONTRACTUALES:
      return <Contractuales />;
    case OptionApplicationMenu.RESUMEN_CLIENTE:
      return <ResumenCliente />;
  }
};
