import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { RootState } from "@store/store";
import { useGetBankAccountsApplicationQuery, usePostStatusApplicationMutation } from "@services/api/applications";
import {
  ButtonType,
  DialogType,
  setButtonClicked,
  setDialogConfig,
  setDialogConfigProcessing,
} from "@store/slices/appSlice";

import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";

import "dayjs/locale/es-mx";

enum FIELD_TYPE {
  CLIENT_NO,
  CONTRACT_NO,
  DISPERSION_DATE,
  NONE,
}

export const Resumen = () => {
  const dispatch = useDispatch();

  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);
  const buttonClicked = useSelector((state: RootState) => state.app.components.buttonPanel.buttonClicked);

  const bankAccounts = useGetBankAccountsApplicationQuery(applicationSelected.id ?? "0-0-0-0-0", {
    skip: applicationSelected.id === undefined,
  });

  const [triggerPostStatusApplication] = usePostStatusApplicationMutation();

  const [fieldEditing, setFieldEditing] = useState<FIELD_TYPE>(FIELD_TYPE.NONE);

  const handleOnEditClicked = (field: FIELD_TYPE) => {
    setFieldEditing(field);
  };

  const handleOnOkClicked = () => {
    setFieldEditing(FIELD_TYPE.NONE);
  };

  const handleOnCleanClicked = () => {
    setFieldEditing(FIELD_TYPE.NONE);
  };

  const handleOnToClient = async () => {
    try {
      await triggerPostStatusApplication({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        statusCode: "CSCLI",
        disableSequenceCheck: true,
      }).unwrap();
      dispatch(
        setDialogConfig({
          shown: false,
          type: DialogType.NONE,
          props: undefined,
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setDialogConfigProcessing(false));
      dispatch(setButtonClicked({ btn: undefined, clicked: false }));
    }
  };

  const handleOnDispersion = async () => {
    try {
      await triggerPostStatusApplication({
        applicationId: applicationSelected.id ?? "0-0-0-0-0",
        statusCode: "CSDIS",
        disableSequenceCheck: true,
      }).unwrap();
      dispatch(
        setDialogConfig({
          shown: false,
          type: DialogType.NONE,
          props: undefined,
        }),
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setDialogConfigProcessing(false));
      dispatch(setButtonClicked({ btn: undefined, clicked: false }));
    }
  };

  useEffect(() => {
    if (buttonClicked.btn === ButtonType.LIBERAR_DISPERSION && buttonClicked.clicked) {
      handleOnDispersion();
    }

    if (buttonClicked.btn === ButtonType.CONVERTIR_CLIENTE && buttonClicked.clicked) {
      handleOnToClient();
    }
  }, [buttonClicked]);

  return (
    <Box m={3}>
      <Stack rowGap={2} mt={2}>
        <Typography variant="h4" fontWeight={700} color={"#002652"}>
          No. de cliente:{" "}
          {fieldEditing == FIELD_TYPE.CLIENT_NO ? (
            <TextField size="small"></TextField>
          ) : (
            <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
              0000001
            </Typography>
          )}
          {fieldEditing != FIELD_TYPE.CLIENT_NO && (
            <IconButton size="small" onClick={() => handleOnEditClicked(FIELD_TYPE.CLIENT_NO)}>
              <EditIcon />
            </IconButton>
          )}
          {fieldEditing === FIELD_TYPE.CLIENT_NO && (
            <>
              <IconButton size="small" onClick={handleOnOkClicked}>
                <DoneIcon color="success" />
              </IconButton>
              <IconButton size="small" onClick={handleOnCleanClicked}>
                <ClearIcon color="error" />
              </IconButton>
            </>
          )}
        </Typography>

        <Typography variant="h4" fontWeight={700} color={"#002652"}>
          Tipo de producto:{" "}
          <Typography component={"span"} variant="h4" fontWeight={700} color={"#002652"}>
            {"algo"}
          </Typography>
        </Typography>

        <Typography variant="h4" fontWeight={700} color={"#002652"}>
          No. de contrato:{" "}
          {fieldEditing == FIELD_TYPE.CONTRACT_NO ? (
            <TextField size="small"></TextField>
          ) : (
            <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
              XXXXXXXXXX-X
            </Typography>
          )}
          {fieldEditing != FIELD_TYPE.CONTRACT_NO && (
            <IconButton size="small" onClick={() => handleOnEditClicked(FIELD_TYPE.CONTRACT_NO)}>
              <EditIcon />
            </IconButton>
          )}
          {fieldEditing === FIELD_TYPE.CONTRACT_NO && (
            <>
              <IconButton size="small" onClick={handleOnOkClicked}>
                <DoneIcon color="success" />
              </IconButton>
              <IconButton size="small" onClick={handleOnCleanClicked}>
                <ClearIcon color="error" />
              </IconButton>
            </>
          )}
        </Typography>

        <Typography variant="h4" fontWeight={700} color={"#002652"}>
          Fecha de dispersión:{" "}
          {fieldEditing == FIELD_TYPE.DISPERSION_DATE ? (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                // disablePast
                format="DD/MM/YYYY"
                label={"Fecha"}
                // value={dayjs(formik.values.date_visit)}
                slotProps={{
                  textField: {
                    required: true,
                    name: "dispersion_date",
                    size: "small",
                    // onBlur: formik.handleBlur,
                    // error: formik.touched.date_visit && Boolean(formik.errors.date_visit),
                    // helperText: formik.touched.date_visit ? formik.errors.date_visit : "",
                  },
                }}
                // onChange={(date) => formik.setFieldValue("date_visit", dayjs(date).format("YYYY-MM-DD"))}
              />
            </LocalizationProvider>
          ) : (
            <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
              --/--/----
            </Typography>
          )}
          {fieldEditing != FIELD_TYPE.DISPERSION_DATE && (
            <IconButton size="small" onClick={() => handleOnEditClicked(FIELD_TYPE.DISPERSION_DATE)}>
              <EditIcon />
            </IconButton>
          )}
          {fieldEditing === FIELD_TYPE.DISPERSION_DATE && (
            <>
              <IconButton size="small" onClick={handleOnOkClicked}>
                <DoneIcon color="success" />
              </IconButton>
              <IconButton size="small" onClick={handleOnCleanClicked}>
                <ClearIcon color="error" />
              </IconButton>
            </>
          )}
        </Typography>

        <Typography variant="h4" fontWeight={700} color={"#002652"}>
          Monto Dispersado:{" "}
          <Typography component={"span"} variant="h4" fontWeight={700} color={"#002652"}>
            {applicationSelected.montoContratado}
          </Typography>{" "}
        </Typography>

        <Typography variant="h4" fontWeight={700} color={"#002652"}>
          Plazo:{" "}
          <Typography component={"span"} variant="h4" fontWeight={700} color={"#002652"}>
            {applicationSelected.plazoContratado}
          </Typography>
        </Typography>

        <Typography variant="h4" fontWeight={700} color={"#002652"}>
          Cuenta CLABE de Deposito:{" "}
          <Typography component={"span"} variant="h4" color={"#528CD6"} fontWeight={"400"}>
            {(bankAccounts.currentData?.length ?? 0) > 0
              ? bankAccounts.currentData?.at(0)?.clabe
              : "XXX XXX XXXXXXXXXXX X"}
          </Typography>{" "}
        </Typography>
      </Stack>
    </Box>
  );
};
