import { useEffect, useState } from "react";

import { store } from "@store/store";
import { createSelector } from "@reduxjs/toolkit";

import { LoanAppDataGrid } from "@components/LoanAppDataGrid";
import { CustomTabPanel, CustomTabs } from "@components/CustomTabs";

export const Dashboard = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [configTabs, setConfigTabs] = useState<{
    tabs: Array<"Solicitudes" | "Perdieron interés" | "Rechazados" | "Autorizados" | "Clientes">;
    cols: Array<Array<string>>;
  }>({ tabs: [], cols: [] });

  const permissions = (state = store.getState()) => state.app.session.permissions;
  const sessionPermissions = createSelector([permissions], (permissions) => {
    return permissions.filter((permission) =>
      ["MSOL", "MPIN", "MAUT", "MREC", "MCLI"].includes(permission.module.code),
    );
  });

  const handleOnChangeTab = async (_: string, indexTab: number) => {
    setCurrentTab(indexTab);
  };

  useEffect(() => {
    let tabs = Array();
    let cols = Array();
    sessionPermissions(store.getState()).forEach((permission) => {
      if (permission.can_watch === false) {
        return;
      }
      switch (permission.module.code) {
        case "MSOL":
          tabs.push("Solicitudes");
          cols.push([
            "solicitudId",
            "razonSocial",
            "rfc",
            "nombre",
            "montoSolicitado",
            "plazoSolicitado",
            "tiempoPantalla",
            "tiempoTotal",
            "etapa",
            "subEtapa",
            "estatus",
            "macroCanal",
            "canal",
            "asignacion",
            "comentarios",
            "perdioInteres",
          ]);
          break;
        case "MPIN":
          tabs.push("Perdieron interés");
          cols.push([
            "solicitudId",
            "razonSocial",
            "rfc",
            "nombre",
            "montoSolicitado",
            "plazoSolicitado",
            "tiempoPantalla",
            "tiempoTotal",
            "etapa",
            "subEtapa",
            "estatus",
            "macroCanal",
            "canal",
            "asignacion",
            "comentarios",
            "expediente",
            "perdioInteres",
          ]);
          break;
        case "MAUT":
          tabs.push("Autorizados");
          cols.push([
            "solicitudId",
            "razonSocial",
            "rfc",
            "nombre",
            "montoSolicitado",
            "plazoSolicitado",
            "tiempoPantalla",
            "tiempoTotal",
            "etapa",
            "subEtapa",
            "estatus",
            "macroCanal",
            "canal",
            "asignacion",
            "comentarios",
            "expediente",
            "perdioInteres",
          ]);
          break;
        case "MREC":
          tabs.push("Rechazados");
          cols.push([
            "solicitudId",
            "razonSocial",
            "rfc",
            "nombre",
            "montoSolicitado",
            "plazoSolicitado",
            "fechaRechazo",
            "tiempoTotal",
            "etapa",
            "subEtapa",
            "macroCanal",
            "canal",
            "asignacion",
            "comentarios",
            "expediente",
          ]);
          break;
        case "MCLI":
          tabs.push("Clientes");
          cols.push([
            "solicitudId",
            "razonSocial",
            "rfc",
            "nombre",
            "montoContratado",
            "plazoContratado",
            "tiempoTotalProceso",
            "fechaDispersion",
            "macroCanal",
            "canal",
            "asignacion",
            "comentarios",
            "expediente",
          ]);
          break;
      }
    });
    setConfigTabs({
      tabs: tabs,
      cols: cols,
    });
  }, []);

  return (
    <>
      <CustomTabs titleTabs={configTabs.tabs} onChangeTab={handleOnChangeTab}></CustomTabs>
      {configTabs.tabs.map((tab, index) => {
        return (
          <CustomTabPanel key={index} index={index} value={currentTab} height={"93%"}>
            <LoanAppDataGrid refreshData={index === currentTab} applicationType={tab} cols={configTabs.cols[index]} />
          </CustomTabPanel>
        );
      })}
    </>
  );
};
