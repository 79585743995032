import Chart from "chart.js/auto";
import { CategoryScale, ChartData, ChartOptions } from "chart.js";
import { Radar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

interface CategoriasAnalisisGraficoProps {
  data: Array<number>;
}

export const CategoriasAnalisisGrafico = (props: CategoriasAnalisisGraficoProps) => {
  const data: ChartData<"radar"> = {
    labels: [
      "Ventas, Compras, Flujo",
      "Clientes, Proveedores, P&G",
      "Nómina y empleados",
      "Financieros Declarados",
      "Buró Acreditado",
      "Buró Persona",
      "CSF & Otros",
    ],
    datasets: [
      {
        fill: false,
        pointRadius: 0,
        // data: [81, 83, 62, 79, 79, 80, 80],
        data: props.data,
      },
    ],
  };

  const options: ChartOptions<"radar"> = {
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 100,
        angleLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };
  return <Radar data={data} options={options} />;
};
