import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid2 as Grid, Stack, TextField, Typography } from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@store/store";
import { DialogType, setDialogConfig } from "@store/slices/appSlice";
import { usePostCommentByAppMutation } from "@services/api/applications";

export interface MissingDocsSegmentProps {
  isProcessing?: boolean;
  onBackClick?: () => void;
  onConfirmClick?: () => void;
}

export const DocumentosFaltantes = (props: MissingDocsSegmentProps) => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState<boolean>(props.isProcessing ?? false);

  const [triggerPostComment] = usePostCommentByAppMutation();

  const userInfo = useSelector((state: RootState) => state.app.session.user);
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const formik = useFormik({
    initialValues: {
      missingFiles: "",
    },
    validationSchema: yup.object().shape({
      missingFiles: yup.string().required("El campo no puede estar vacio"),
    }),
    onSubmit: async (values) => {
      try {
        setIsProcessing(true);
        await triggerPostComment({
          applicationId: applicationSelected.id ?? "0-0-0-0-0",
          payload: {
            comment: values.missingFiles,
            user_id: userInfo.id,
          },
        }).unwrap();
        props.onConfirmClick?.();

        dispatch(
          setDialogConfig({
            shown: false,
            type: DialogType.NONE,
            props: undefined,
          }),
        );
      } catch {
      } finally {
        setIsProcessing(false);
      }
    },
  });

  return (
    <Box margin={4}>
      <Stack textAlign={"center"} spacing={2}>
        <Typography color="#002652" fontWeight={600}>
          Documentos faltantes
        </Typography>
        <form onSubmit={formik.handleSubmit} noValidate>
          <TextField
            id="missingFiles"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.missingFiles}
            error={formik.touched.missingFiles && Boolean(formik.errors.missingFiles)}
            fullWidth
            multiline
            rows={4}
            label={"Escribe cuales son los documentos faltantes"}
          />

          <Grid
            container
            flexDirection={"row"}
            gap={2}
            justifyContent={"space-around"}
            alignItems={"center"}
            marginTop={2}
          >
            <Grid>
              <Button variant={"blue_outlined"} onClick={props.onBackClick} disabled={isProcessing}>
                Regresar
              </Button>
            </Grid>
            <Grid>
              <LoadingButton variant={"contained"} type="submit" loading={isProcessing} disabled={isProcessing}>
                Continuar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </Box>
  );
};
