import { useState } from "react";

import { CustomTabPanel, CustomTabs } from "@components/CustomTabs";
import { DocumentosDictamen } from "@components/SolicitudSegmentos/Dictamen/DocumentosDictamen";
import { ModuloDictamen } from "@components/SolicitudSegmentos/Dictamen/ModuloDictamen";
import { useDispatch } from "react-redux";
import { ButtonType, setVisibleButtons } from "@store/slices/appSlice";

export const Dictamen = () => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleOnChangeTab = async (titleTab: string, indexTab: number) => {
    setCurrentTab(indexTab);
    if (titleTab === "Documentos Dictamen") {
      dispatch(setVisibleButtons([ButtonType.RECHAZAR, ButtonType.RECHAZAR_BLOQUEAR]));
    } else {
      dispatch(
        setVisibleButtons([
          ButtonType.VALIDAR_DICTAMEN,
          ButtonType.DOCS_FALTANTES,
          ButtonType.RECHAZAR,
          ButtonType.RECHAZAR_BLOQUEAR,
        ]),
      );
    }
  };

  return (
    <>
      <CustomTabs titleTabs={["Documentos Dictamen", "Módulo Dictamen"]} onChangeTab={handleOnChangeTab}></CustomTabs>
      <CustomTabPanel index={0} value={currentTab}>
        <DocumentosDictamen />
      </CustomTabPanel>
      <CustomTabPanel index={1} value={currentTab}>
        <ModuloDictamen />
      </CustomTabPanel>
    </>
  );
};
