import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import * as yup from "yup";
import { UUID } from "crypto";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { DatePicker } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  useGetScheduledVisitQuery,
  usePatchScheduledVisitMutation,
  usePostScheduledVisitMutation,
  usePutApplicationsMutation,
} from "@services/api/applications";

import { RootState } from "@store/store";
import { useGetAddressQuery } from "@services/api/address";
import { DialogType, setDialogConfig } from "@store/slices/appSlice";
import { useGetAddressTypesQuery, useGetCityQuery, useGetColoniaQuery, useGetStateQuery } from "@services/api/catalogs";

import "dayjs/locale/es-mx";

export interface OnSiteVisitProps {
  applicationId: UUID | undefined;
  clientDetailsId: UUID | undefined;
}

export const Agendar = () => {
  const dispatch = useDispatch();
  const applicationSelected = useSelector((state: RootState) => state.app.applicationSelected);

  const addressTypes = useGetAddressTypesQuery();
  const operativeAddress = useGetAddressQuery(
    {
      clientDetailsId: applicationSelected.clientDetailId ?? "0-0-0-0-0",
      addressTypeId: addressTypes.currentData?.find((type) => type.code === "CDOP")?.id ?? "0-0-0-0-0",
    },
    { skip: !addressTypes.isSuccess },
  );

  const cityOperative = useGetCityQuery(operativeAddress.currentData?.city_id ?? 0, {
    skip: !operativeAddress.isSuccess,
  });

  const coloniaOperative = useGetColoniaQuery(operativeAddress.currentData?.colonia_id ?? 0, {
    skip: !operativeAddress.isSuccess,
  });

  const stateOperative = useGetStateQuery(operativeAddress.currentData?.state_id ?? 0, {
    skip: !operativeAddress.isSuccess,
  });

  const operativeAddressValidated = useGetAddressQuery(
    {
      clientDetailsId: applicationSelected.clientDetailId ?? "0-0-0-0-0",
      addressTypeId: addressTypes.currentData?.find((type) => type.code === "CDOC")?.id ?? "0-0-0-0-0",
    },
    { skip: !addressTypes.isSuccess },
  );

  const cityOpValidated = useGetCityQuery(operativeAddressValidated.currentData?.city_id ?? 0, {
    skip: operativeAddressValidated.data == undefined,
  });

  const coloniaOpValidated = useGetColoniaQuery(operativeAddressValidated.currentData?.colonia_id ?? 0, {
    skip: operativeAddressValidated.data == undefined,
  });

  const stateOpValidated = useGetStateQuery(operativeAddressValidated.currentData?.state_id ?? 0, {
    skip: operativeAddressValidated.data == undefined,
  });

  const existingScheduledVisit = useGetScheduledVisitQuery(applicationSelected.id);

  const [scheduledVisitId, setScheduledVisitId] = useState<UUID | undefined>();
  const [existScheduledVisit, SetExistScheduledVisit] = useState<boolean>(false);
  const [fullAddressOperative, setFullAddressOperative] = useState<string | undefined>();
  const [fullAddressOpValidated, setFullAddressOpValidated] = useState<string | undefined>();

  const [triggerPatchApplication] = usePutApplicationsMutation();
  const [triggerPostScheduledVisit] = usePostScheduledVisitMutation();
  const [triggerPatchScheduledVisit] = usePatchScheduledVisitMutation();

  const formik = useFormik({
    initialValues: {
      date_visit: "",
      hour_visit: "",
    },
    validationSchema: yup.object().shape({
      date_visit: yup.string().required("Campo requerido"),
      hour_visit: yup.string().required("Campo requerido"),
    }),
    onSubmit: async (values) => {
      dispatch(
        setDialogConfig({
          shown: true,
          type: DialogType.VALIDAR_VISITA,
          props: {
            title: "¿Estas seguro de agendar la visita de?",
            date: values.date_visit,
            time: values.hour_visit,
          },
          onAcceptClick: handleOnContinueClicked,
        }),
      );
    },
  });

  const handleOnContinueClicked = async () => {
    const reformatTime = formik.values.hour_visit;
    try {
      if (existScheduledVisit) {
        await triggerPatchScheduledVisit({
          appId: applicationSelected.id,
          scheduleVisitId: scheduledVisitId,
          timestamp: `${formik.values.date_visit}T${reformatTime}`,
        }).unwrap();
      } else {
        await triggerPostScheduledVisit({
          appId: applicationSelected.id,
          timestamp: `${formik.values.date_visit}T${reformatTime}`,
        }).unwrap();
        SetExistScheduledVisit(true);
      }

      await triggerPatchApplication({
        applicationId: applicationSelected.id,
        body: {
          ocular_visit: true,
        },
      }).unwrap();

      dispatch(
        setDialogConfig({
          shown: false,
          type: DialogType.NONE,
          props: undefined,
          isProcessing: false,
        }),
      );
    } catch (error) {
      console.log("Error al actualizar visita ocular: %s", error);
    }
  };

  useEffect(() => {
    if (
      operativeAddress.isSuccess &&
      cityOperative.isSuccess &&
      coloniaOperative.isSuccess &&
      stateOperative.isSuccess
    ) {
      let fullAddress = operativeAddress.currentData?.street;

      fullAddress += operativeAddress.currentData?.no_ext ? `, ${operativeAddress.currentData?.no_ext}` : "";
      fullAddress += operativeAddress.currentData?.no_int ? `, ${operativeAddress.currentData?.no_int}` : "";
      fullAddress += coloniaOperative.currentData?.name ? `, ${coloniaOperative.currentData?.name}` : "";
      fullAddress += operativeAddress.currentData?.cp ? `, ${operativeAddress.currentData?.cp}` : "";
      fullAddress += cityOperative.currentData?.name ? `, ${cityOperative.currentData?.name}` : "";
      fullAddress += stateOperative.currentData?.name ? `, ${stateOperative.currentData?.name}.` : "";

      setFullAddressOperative(fullAddress);
    }
  }, [operativeAddress, cityOperative, coloniaOperative, stateOperative]);

  useEffect(() => {
    if (
      operativeAddressValidated.isSuccess &&
      cityOpValidated.isSuccess &&
      coloniaOpValidated.isSuccess &&
      stateOpValidated.isSuccess
    ) {
      let fullAddress = operativeAddress.currentData?.street;

      fullAddress += operativeAddressValidated.currentData?.no_ext
        ? `, ${operativeAddressValidated.currentData?.no_ext}`
        : "";
      fullAddress += operativeAddressValidated.currentData?.no_int
        ? `, ${operativeAddressValidated.currentData?.no_int}`
        : "";
      fullAddress += coloniaOpValidated.currentData?.name ? `, ${coloniaOpValidated.currentData?.name}` : "";
      fullAddress += operativeAddressValidated.currentData?.cp ? `, ${operativeAddressValidated.currentData?.cp}` : "";
      fullAddress += cityOpValidated.currentData?.name ? `, ${cityOpValidated.currentData?.name}` : "";
      fullAddress += stateOpValidated.currentData?.name ? `, ${stateOpValidated.currentData?.name}.` : "";

      setFullAddressOpValidated(fullAddress);
    } else {
      setFullAddressOpValidated("Sin confirmar");
    }
  }, [operativeAddressValidated, cityOpValidated, coloniaOpValidated, stateOpValidated]);

  useEffect(() => {
    SetExistScheduledVisit(existingScheduledVisit.isSuccess);
    if (existingScheduledVisit.isSuccess) {
      const dateTmp = existingScheduledVisit.data.data.data[0].date;

      formik.setFieldValue("hour_visit", dateTmp.split("T")[1].substring(0, 5));
      formik.setFieldValue("date_visit", dateTmp.split("T")[0]);
      setScheduledVisitId(existingScheduledVisit.data.data.data[0].id);
    }
  }, [existingScheduledVisit]);

  return (
    <Box margin={3}>
      <Typography variant="h4" color={"#002652"} marginTop={2} fontWeight={400}>
        Dirección operativa
      </Typography>
      <Typography variant="body1" color={"#528CD6"} fontWeight={600}>
        {fullAddressOperative}
      </Typography>
      <Typography variant="h4" color={"#002652"} marginTop={2} fontWeight={400}>
        Dirección operativa confirmada
      </Typography>
      <Typography variant="body1" color={"#528CD6"} fontWeight={600}>
        {fullAddressOpValidated}
      </Typography>
      <Typography variant="h4" color={"#002652"} marginTop={2} fontWeight={400}>
        Agenda la visita ocular
      </Typography>
      <Typography variant="body1" color={"#002652"}>
        Recuerda que este es el horario que le aparecera al cliente en pantalla
      </Typography>
      <Box marginTop={6} px={8}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid container columnSpacing={2}>
            <Grid size={6}>
              <DemoItem>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                  <DatePicker
                    disablePast
                    format="DD/MM/YYYY"
                    label={"Fecha"}
                    value={dayjs(formik.values.date_visit)}
                    slotProps={{
                      textField: {
                        required: true,
                        name: "date_visit",
                        onBlur: formik.handleBlur,
                        error: formik.touched.date_visit && Boolean(formik.errors.date_visit),
                        helperText: formik.touched.date_visit ? formik.errors.date_visit : "",
                      },
                    }}
                    onChange={(date) => formik.setFieldValue("date_visit", dayjs(date).format("YYYY-MM-DD"))}
                  />
                </LocalizationProvider>
              </DemoItem>
            </Grid>
            <Grid size={6}>
              <FormControl fullWidth required>
                <InputLabel id="visiting_hours_label">Hora</InputLabel>
                <Select
                  name="hour_visit"
                  labelId="visiting_hours_label"
                  placeholder="Hora"
                  input={<OutlinedInput label="Hora" />}
                  value={formik.values.hour_visit}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.hour_visit && Boolean(formik.errors.hour_visit)}
                >
                  <MenuItem value={"09:00"}>09:00 am - 10:00 am</MenuItem>
                  <MenuItem value={"10:00"}>10:00 am - 11:00 am</MenuItem>
                  <MenuItem value={"11:00"}>11:00 am - 12:00 pm</MenuItem>
                  <MenuItem value={"12:00"}>12:00 pm - 13:00 pm</MenuItem>
                  <MenuItem value={"13:00"}>13:00 pm - 14:00 pm</MenuItem>
                  <MenuItem value={"14:00"}>14:00 pm - 15:00 pm</MenuItem>
                  <MenuItem value={"15:00"}>15:00 pm - 16:00 pm</MenuItem>
                  <MenuItem value={"16:00"}>16:00 pm - 17:00 pm</MenuItem>
                  <MenuItem value={"17:00"}>17:00 pm - 18:00 pm</MenuItem>
                  <MenuItem value={"18:00"}>18:00 pm - 19:00 pm</MenuItem>
                  <MenuItem value={"19:00"}>19:00 pm - 20:00 pm</MenuItem>
                </Select>
                {Boolean(formik.touched.hour_visit) && <FormHelperText>{formik.errors.hour_visit}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container flexDirection={"row"} justifyContent={"space-around"} alignItems={"center"} marginTop={10}>
            <Grid>
              <Button variant={"blue_outlined"} type="submit" disabled={!existScheduledVisit}>
                Cambiar
              </Button>
            </Grid>
            <Grid>
              <Button variant={"contained"} type="submit" disabled={existScheduledVisit}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};
